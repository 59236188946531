<template>
  <!-- begin:: Real Code -->
  <div
    class="container-fluid p-0"
    style="min-height: 400px !important"
  >
    <div class="row p-0 m-0">
      <div class="col-md-12 p-0 m-0">
        <div class="container fix-frame">
          <div class="row d-flex justify-content-center">
            <div class="col-md-12">
              <div>
                <h4 class="d-inline-block">
                  <a
                    :href="
                      '/' + this.$i18n.locale + '/live/football/world-standing'
                    "
                    class="link-header"
                  >
                    <img
                      src="https://cdn.77577cf.com/assets/img/icon-football.png"
                      width="45"
                      class="p-1"
                    />{{ $t("football.word-standing.word-standing") }}</a
                  >
                </h4>
              </div>
              <div class="col-md-12" v-if="loading">
                <div class="row">
                  <div
                    class="col-12 col-md-7 pr-0"
                    style="margin-left: 40px; margin-top: -20px"
                  >
                    <p style="font-size: 13px !important">
                      {{ $t("football.live-score.information-is-loading") }}
                      <img
                        src="/images/spinner.gif"
                        alt="spinner"
                        style="width: 8px"
                      />
                    </p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="tab-content p-2">
                    <div
                      id="worldStanding01"
                      role="tabpanel"
                    >
          
                      <div class="table-responsive">
                        <table
                          class="table table-sm table-striped table-hover text-center table-bordered"
                        >
                          <thead class="bg-dark text-white">
                            <tr>
                              <th>
                                {{ $t("football.word-standing.ranking") }}
                              </th>
                              <th style="width: 180px">
                                {{ $t("football.word-standing.team") }}
                              </th>
                            
                              <th>{{ $t("football.word-standing.points") }}</th>
                              <th>
                                {{
                                  $t("football.word-standing.ranking-changes")
                                }}
                              </th>
                              <th>
                                {{
                                  $t("football.word-standing.points-changes")
                                }}
                              </th>
                            </tr>
                          </thead>
                          <tbody></tbody>
                          <tbody
                            v-for="standings in WorldStandings"
                            :key="standings.team_id"
                          >
                            <tr v-if="WorldStandings">
                              <td>{{ standings.rank }}</td>
                              <td class="text-left">
                                {{ standings.team_name }}
                              </td>
                              
                              <td>{{ standings.point }}</td>
                              <td>
                                {{ standings.rank_change }}
                              </td>
                              <td>
                                {{ standings.point - standings.pre_point }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <center>
                          <p style="font-size: 15px !important">
                            {{
                              $t("football.live-score.information-is-loading")
                            }}
                            <img
                              src="/images/spinner.gif"
                              alt="spinner"
                              style="width: 12px"
                            />
                          </p>
                        </center>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end::of Real COde -->
</template>
<script>

export default {
  components: {
  
  },
  created() {
    this.getWorldStanding();
  },
  mounted() {
    window.addEventListener("scroll", () => {
        this.loading = true;
        this.getSpecificMatchList(this.currentPage + 1);
    
    });
  },
  data() {
    return {
      WorldStandings: null,
      loading: false,
      pagesCount: null,
      currentPage: 1,
      pageNumber: null,
    };
  },
  methods: {
    getWorldStanding() {
      this.loading = true; //the loading begin
      this.$axios
        .get(`${this.BASE_SERVER_URI}/api6/football/get-world-standings`)
        .then((response) => {
          this.WorldStandings = response.data.worldStanding;
          this.pagesCount = response.data.totalPages;
        })
        .catch((error) => {
          console.log(error);
        }) 
        .finally(() => (this.loading = false)); // set loading to false when request finish
    },
    getSpecificMatchList(pageNumber) {
      this.loading = true; 
      this.$axios
        .get(
          `${this.BASE_SERVER_URI}/api6/football/get-world-standings?pageNumber=${pageNumber}`
        )
        .then((response) => {
          this.WorldStandings = response.data.worldStanding;
          this.pagesCount = response.data.totalPages;
          this.currentPage = this.pagesCount;
        })
        .catch((error) => {
          console.log(error);
        }) 
        .finally(() => (this.loading = false));
    },
  },
};
</script>