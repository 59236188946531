<template>
  <div
    class="container-fluid p-0"
    style="min-height: 400px !important; width: 67%"
  >
    <div class="row p-0 m-0" style="background-color: white">
      <div class="col-md-12 p-0 m-0">
        <div class="container fix-frame">
          <br />
          <div class="d-flex justify-content-center row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-12 col-md-7 pr-0">
                  <h4 class="d-inline-block customPhoneH4TextSize">
                    <img
                      src="/images/icon-football.png"
                      width="45"
                      class="p-1 customPhoneH4Icon"
                    />
                    {{ $t("football.match-result.match-result") }}
                    <span v-if="loaderForSpecificDate">
                      <img
                        src="/images/spinner.gif"
                        alt="spinner"
                        style="width: 15px"
                      />
                    </span>
                  </h4>
                </div>
                <div class="col-12 col-md-5 pl-0">
                  <div class="row p-0 m-0 mt-1">
                    <div
                      class="col-4 col-md-6 mr-0 pr-0"
                      style="
                        display: inline-flex;
                        border: 0px solid rgb(0, 0, 0);
                      "
                    >
                      <div
                        class="col-2 col-md-12 justify-content-md-right text-right mr-0 pr-0"
                      >
                        <div class="btn-group" style="width: 100%">
                          <div class="dropdown bootstrap-select mr-2">
                            <select
                              data-dropup-auto="false"
                              data-display="static"
                              data-style="btn-danger"
                              data-size="8"
                              id="selectHotList"
                              data-toggle="popover"
                              data-placement="top"
                              data-container="body"
                              class="selectpicker mr-2"
                            >
                              <option class="bs-title-option" value=""></option>
                              <option value="">
                                {{ $t("football.live-score.all-leagsue") }}
                              </option>
                              <option value="all-hot-matches">
                                {{ $t("football.live-score.hot-matches") }}
                              </option></select
                            ><button
                              type="button"
                              tabindex="-1"
                              class="btn dropdown-toggle bs-placeholder btn-danger"
                              data-display="static"
                              data-toggle="dropdown"
                              role="combobox"
                              aria-owns="bs-select-3"
                              aria-haspopup="listbox"
                              aria-expanded="false"
                              data-id="selectHotList"
                            >
                              <div class="filter-option">
                                <div class="filter-option-inner">
                                  <div class="filter-option-inner-inner">
                                    {{ $t("football.live-score.hot-today") }}
                                  </div>
                                </div>
                              </div>
                            </button>
                            <div class="dropdown-menu" style="overflow: hidden">
                              <div
                                class="inner show"
                                role="listbox"
                                id="bs-select-3"
                                tabindex="-1"
                                style="overflow-y: auto"
                              >
                                <ul
                                  class="dropdown-menu inner show"
                                  role="presentation"
                                  style="margin-top: 0px; margin-bottom: 0px"
                                >
                                  <li>
                                    <a
                                      v-on:click="getMatchList(dayNumber, -1)"
                                      role="option"
                                      class="dropdown-item"
                                      id="bs-select-3-0"
                                      tabindex="0"
                                      ><span class="text"
                                        >{{
                                          $t("football.live-score.all-league")
                                        }}
                                      </span></a
                                    >
                                  </li>
                                  <li
                                    v-for="hotLeagues in hotLeagues"
                                    :key="hotLeagues.id"
                                  >
                                    <a
                                      role="option"
                                      class="dropdown-item"
                                      id="bs-select-3-1"
                                      tabindex="0"
                                      ><span
                                        class="text"
                                        v-on:click="
                                          getHotLeagues(hotLeagues.id)
                                        "
                                      >
                                        {{
                                          this.$i18n.locale == "cn"
                                            ? hotLeagues.nameCn
                                            : hotLeagues.nameEn
                                        }}
                                      </span></a
                                    >
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-8 col-md-6 p-0 m-0 pl-1 text-right">
                      <div class="input-group mb-2 p-0">
                        <input
                          type="text"
                          id="inputLeagueTeamName"
                          v-model="inputLeagueTeamName"
                          :placeholder="
                            $t('football.live-score.league') +
                            ' / ' +
                            $t('football.live-score.team')
                          "
                          data-content="Please keyin the relevant name/keyword"
                          data-toggle="popover"
                          data-placement="top"
                          data-container="body"
                          class="form-control p-1 pl-1 customPhoneTextSize"
                          style="font-size: 80%"
                        />
                        <div class="input-group-append">
                          <button
                            v-on:click="searchTeamLeagueName()"
                            class="input-group-text bg-danger text-white btn p-1 customPhoneTextSize"
                          >
                            {{ $t("football.live-score.search") }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3 mt-2">
                <div class="col-12 col-md-5 col-lg-6 p-0 mb-2">
                  <div class="col-12">
                    <div class="bg-dark text-white p-2">
                      {{ $t("football.match-result.previous-7-day") }}
                    </div>
                  </div>
                  <div class="col-12 align-middle">
                    <button
                      id="previous-button"
                      style=""
                      type="button"
                      class="btn btn-sm m-1 text-white"
                      fdprocessedid="a6slra"
                      v-for="val in [-1, -2, -3, -4, -5, -6, -7]"
                      :key="val"
                      v-on:click="getMatchList(val, 1)"
                      :class="
                        dayClickedBtnVal == val ? 'btn-danger' : 'btn-primary'
                      "
                    >
                      {{ prevDate(val) }}
                    </button>
                  </div>
                </div>
                <div class="col-12 col-md-5 col-lg-6 p-0 mb-2">
                  <div class="col-12">
                    <div class="bg-dark text-white p-2">
                      {{ $t("football.match-result.next-7-day") }}
                    </div>
                  </div>
                  <div class="col-12 align-middle">
                    <button
                      id="next-button"
                      style=""
                      type="button"
                      class="btn btn-sm m-1 text-white"
                      fdprocessedid="a6slra"
                      v-for="val in [1, 2, 3, 4, 5, 6, 7]"
                      :key="val"
                      v-on:click="getMatchList(val, 1)"
                      :class="
                        dayClickedBtnVal == val ? 'btn-danger' : 'btn-primary'
                      "
                    >
                      {{ nextDate(val) }}
                    </button>
                  </div>
                </div>
              </div>
              <!-- ad -->
              <div class="container-fluid p-0 mobile-version">
                <div class="row p-0 m-0">
                  <div class="col-md-12 p-0 m-0">
                    <div class="bg-dark text-white p-2" v-if="matchList">
                      {{ $date }}
                    </div>
                    <div class="d-flex justify-content-center row">
                      <div class="col-md-12 col-lg-12">
                        <div class="row d-block mt-0 d-md-none">
                          <div class="col-12"></div>
                          <div
                            class="col-sm-12"
                            v-for="(match, index) in matchList"
                            :key="index"
                          >
                            <div class="row mb-3 state-default">
                              <div class="col-sm-12">
                                <div
                                  class="row m-0 p-1"
                                  :style="`background-color:${
                                    match.league_Info &&
                                    match.league_Info.primary_color
                                      ? match.league_Info.primary_color
                                      : 'black'
                                  };`"
                                >
                                  <div class="col-12 text-left text-white">
                                    {{
                                      new Date(match.match_timing)
                                        .toLocaleString([], {
                                          hour: "2-digit",
                                          minute: "2-digit",
                                        })
                                        .slice(0, 5)
                                    }}
                                    <div
                                      title="International Club Friendly"
                                      class="btn p-1 text-white"
                                    >
                                      <span v-if="this.$i18n.locale == 'cn'">
                                        {{
                                          match && league_Info
                                            ? match.league_Info.cn_name
                                            : ""
                                        }}
                                      </span>

                                      <span v-else>
                                        {{
                                          match && league_Info
                                            ? match.league_Info.en_name
                                            : ""
                                        }}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div class="row m-0 p-2 border border-bottom-0">
                                  <div class="col-12">
                                    <!-- begin::display home team name and logo -->
                                    <span>
                                      <div class="row">
                                        <div
                                          class="col-3"
                                          style="position: relative; top: 20px"
                                        >
                                          <!-- begin::match status -->
                                          <span style="font-size: 10px">
                                            <span
                                              v-if="match.status_id == 1"
                                              data-toggle="tooltip"
                                              data-placement="top"
                                              :title="$t('state.upcoming')"
                                            >
                                              {{ $t("state.upcoming-s") }}
                                            </span>
                                            <span
                                              v-if="match.status_id == 2"
                                              style="color: red"
                                              data-toggle="tooltip"
                                              data-placement="top"
                                              :title="$t('state.first-half')"
                                            >
                                              <b>
                                                {{
                                                  $t("state.first-half-s")
                                                }}</b
                                              >
                                              <br />
                                              <b
                                                style="
                                                  margin-left: 2px !important;
                                                "
                                                >{{
                                                  onGoingMatchTime(
                                                    match.counter_timing,
                                                    match.status_id
                                                  )
                                                }}</b
                                              >
                                              <span
                                                class="loader__dot"
                                                style="
                                                  margin-left: 2px !important;
                                                "
                                                >'</span
                                              >
                                            </span>
                                            <span
                                              v-else-if="match.status_id == 3"
                                              style="color: red"
                                              data-toggle="tooltip"
                                              data-placement="top"
                                              :title="$t('state.halftime')"
                                            >
                                              <b>
                                                {{ $t("state.halftime-s") }}</b
                                              >
                                              <span
                                                class="loader__dot"
                                                style="
                                                  margin-left: 3px !important;
                                                "
                                                >'</span
                                              >
                                            </span>
                                            <span
                                              v-else-if="match.status_id == 4"
                                              style="color: red"
                                              data-toggle="tooltip"
                                              data-placement="top"
                                              :title="$t('state.second-half')"
                                            >
                                              <b>
                                                {{
                                                  $t("state.second-half-s")
                                                }}</b
                                              >
                                              <br />
                                              <b
                                                style="
                                                  margin-left: 2px !important;
                                                "
                                                >{{
                                                  onGoingMatchTime(
                                                    match.counter_timing,
                                                    match.status_id
                                                  )
                                                }}</b
                                              >
                                              <span
                                                class="loader__dot"
                                                style="
                                                  margin-left: 2px !important;
                                                "
                                                >'</span
                                              >
                                            </span>
                                            <span
                                              v-else-if="match.status_id == 5"
                                              style="color: red"
                                              data-toggle="tooltip"
                                              data-placement="top"
                                              :title="$t('state.overtime')"
                                            >
                                              <b>{{
                                                $t("state.overtime-s")
                                              }}</b>
                                              <span
                                                class="loader__dot"
                                                style="
                                                  margin-left: 3px !important;
                                                "
                                                >'</span
                                              >
                                            </span>
                                            <span
                                              v-else-if="match.status_id == 7"
                                              style="color: red"
                                              data-toggle="tooltip"
                                              data-placement="top"
                                              :title="$t('state.penalty')"
                                            >
                                              <b>{{ $t("state.penalty") }}</b>
                                              <span
                                                class="loader__dot"
                                                style="
                                                  margin-left: 3px !important;
                                                "
                                                >'</span
                                              >
                                            </span>

                                            <span
                                              v-else-if="match.status_id == 8"
                                              data-toggle="tooltip"
                                              data-placement="top"
                                              style="color: red"
                                              :title="$t('state.finished')"
                                            >
                                              <b>
                                                {{ $t("state.finished-s") }}
                                              </b>
                                            </span>
                                          </span>
                                          <!-- end::match status -->
                                        </div>
                                        <div class="col-5">
                                          <table>
                                            <tr>
                                              <td>
                                                <img
                                                  v-if="match.home_Info"
                                                  :src="
                                                    match.home_Info.logo
                                                      ? match.home_Info.logo
                                                      : '/images/default-football.jpg'
                                                  "
                                                  width="30"
                                                  height="30"
                                                />
                                              </td>
                                              <td>
                                                <span
                                                  style="
                                                    font-size: 0.7rem !important;
                                                    display: -webkit-box;
                                                    max-width: 400px;
                                                    -webkit-line-clamp: 2;
                                                    -webkit-box-orient: vertical;
                                                    overflow: hidden;
                                                    text-overflow: ellipsis;
                                                  "
                                                >
                                                  <span
                                                    v-if="
                                                      this.$i18n.locale == 'cn'
                                                    "
                                                  >
                                                    {{
                                                      match && home_Info
                                                        ? match.home_Info
                                                            .cn_name
                                                        : ""
                                                    }}
                                                  </span>

                                                  <span v-else>
                                                    {{
                                                      match && home_Info
                                                        ? match.home_Info
                                                            .en_name
                                                        : ""
                                                    }}
                                                  </span>
                                                </span>
                                              </td>
                                            </tr>
                                          </table>
                                        </div>
                                        <div class="col-2">
                                          <span
                                            style="
                                              display: inline-block;
                                              padding: 0px 3px;
                                              background-color: yellow;
                                              font-size: 0.5rem !important;
                                            "
                                          >
                                            {{
                                              match.home_Info &&
                                              match.home_Info.yellow_cards > 0
                                                ? match.home_Info.yellow_cards
                                                : ""
                                            }}
                                          </span>

                                          <span
                                            style="
                                              display: inline-block;
                                              padding: 0px 3px;
                                              background-color: red;
                                              color: white;
                                              font-size: 0.5rem !important;
                                            "
                                          >
                                            {{
                                              match.home_Info &&
                                              match.home_Info.red_cards
                                                ? match.home_Info.red_cards
                                                : " "
                                            }}
                                          </span>
                                        </div>
                                        <div class="col-2">
                                          <span
                                            v-if="match.status_id == 8"
                                            style="
                                              margin-left: 5px !important;
                                              font-size: 1rem !important;
                                            "
                                          >
                                            {{
                                              match.home_Info
                                                ? match.home_Info.home_score
                                                : ""
                                            }}
                                          </span>

                                          <span
                                            v-else
                                            style="
                                              margin-left: 5px !important;
                                              font-size: 1rem !important;
                                            "
                                          >
                                            -
                                          </span>
                                        </div>
                                      </div>
                                    </span>
                                    <br />

                                    <span>
                                      <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-5">
                                          <table>
                                            <tr>
                                              <td>
                                                <img
                                                  v-if="match.away_Info"
                                                  :src="
                                                    match.away_Info.logo
                                                      ? match.away_Info.logo
                                                      : '/images/default-football.jpg'
                                                  "
                                                  width="30"
                                                  height="30"
                                                />
                                              </td>
                                              <td>
                                                <span
                                                  style="
                                                    font-size: 0.7rem !important;
                                                    display: -webkit-box;
                                                    max-width: 400px;
                                                    -webkit-line-clamp: 2;
                                                    -webkit-box-orient: vertical;
                                                    overflow: hidden;
                                                    text-overflow: ellipsis;
                                                  "
                                                >
                                                  <span
                                                    v-if="
                                                      this.$i18n.locale == 'cn'
                                                    "
                                                  >
                                                    {{
                                                      match && away_Info
                                                        ? match.away_Info
                                                            .cn_name
                                                        : ""
                                                    }}
                                                  </span>

                                                  <span v-else>
                                                    {{
                                                      match && away_Info
                                                        ? match.away_Info
                                                            .en_name
                                                        : ""
                                                    }}
                                                  </span>
                                                </span>
                                              </td>
                                            </tr>
                                          </table>
                                        </div>

                                        <div class="col-2">
                                          &nbsp;
                                          <span
                                            style="
                                              display: inline-block;
                                              padding: 0px 1px;
                                              background-color: yellow;
                                              font-size: 0.5rem !important;
                                            "
                                          >
                                            {{
                                              match.away_Info &&
                                              match.away_Info.yellow_cards > 0
                                                ? match.away_Info.yellow_cards
                                                : ""
                                            }}
                                          </span>

                                          <span
                                            style="
                                              display: inline-block;
                                              padding: 0px 1px;
                                              background-color: red;
                                              color: white;
                                              font-size: 0.5rem !important;
                                            "
                                          >
                                            {{
                                              match.away_Info &&
                                              match.away_Info.red_cards
                                                ? match.away_Info.red_cards
                                                : " "
                                            }}
                                          </span>
                                        </div>
                                        <div class="col-2">
                                          <span
                                            v-if="match.status_id == 8"
                                            style="
                                              margin-left: 5px !important;
                                              font-size: 1rem !important;
                                            "
                                          >
                                            {{
                                              match.away_Info
                                                ? match.away_Info.away_score
                                                : ""
                                            }}
                                          </span>

                                          <span
                                            v-else
                                            style="
                                              margin-left: 5px !important;
                                              font-size: 1rem !important;
                                            "
                                          >
                                            -
                                          </span>
                                        </div>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <tbody v-if="matchList == ''">
                            <tr>
                              <td colspan="14">
                                <div
                                  class="text-white p-2"
                                  style="background-color: rgb(52, 144, 220)"
                                >
                                  {{
                                    $t(
                                      "basketball.live-score.temporarily-no-match"
                                    )
                                  }}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="row pt-2 pc-version"
                style="min-height: 279px; background-color: white"
              >
                <div class="col-md-12">
                  <table
                    class="table table-sm table-bordered table-hover text-center table-striped"
                  >
                    <thead class="bg-dark text-white">
                      <tr style="height: 10px !important">
                        <th style="width: 96px !important">
                          <span style="font-size: 10px !important">
                            {{ $date }}
                          </span>
                        </th>
                        <th>{{ $t("football.live-score.time") }}</th>
                        <th>{{ $t("football.live-score.status") }}</th>
                        <th>{{ $t("football.live-score.home-team") }}</th>
                        <th>{{ $t("football.live-score.score") }}</th>
                        <th>{{ $t("football.live-score.away-team") }}</th>
                        <th>{{ $t("basketball.live-score.halftime") }}</th>
                      </tr>
                    </thead>

                    <tbody v-for="match in matchList" :key="match.id">
                      <tr
                        v-if="
                          match.status_id != 9 &&
                          match.status_id != 12 &&
                          match.status_id != 13
                        "
                      >
                        <td
                          class="align-middle"
                          :style="`background-color:${
                            match.league_Info && match.league_Info.primary_color
                              ? match.league_Info.primary_color
                              : 'black'
                          };`"
                        >
                          <div
                            class="p-0 text-dark align-middle"
                            style="font-size: 12px"
                          >
                            <span
                              style="color: white"
                              v-on:click="
                                getLeagueDetails(match.league_Info.id)
                              "
                              data-toggle="modal"
                              data-target="#leaguePopup"
                            >
                              <span v-if="this.$i18n.locale == 'cn'">
                                {{
                                  match && match.league_Info
                                    ? match.league_Info.cn_name
                                    : ""
                                }}
                              </span>

                              <span v-else>
                                {{
                                  match && match.league_Info
                                    ? match.league_Info.en_name
                                    : ""
                                }}
                              </span>
                            </span>
                          </div>
                        </td>
                        <td class="align-middle" style="font-size: 12px">
                          {{ changeTimeZone(match.match_timing) }}
                        </td>
                        <td class="align-middle" style="font-size: 10px">
                          <span>
                            <span
                              v-if="match.status_id == 1"
                              data-toggle="tooltip"
                              data-placement="top"
                              :title="$t('state.upcoming')"
                            >
                              {{ $t("state.upcoming-s") }}
                            </span>

                            <span
                              style="color: red"
                              data-toggle="tooltip"
                              data-placement="top"
                              :title="$t('state.finished')"
                              v-else-if="match.status_id == 8"
                            >
                              {{ $t("state.finished-s") }}
                            </span>

                            <span title="status" v-else> </span>
                          </span>
                        </td>
                        <td
                          class="text-right align-middle"
                          style="width: 250px !important"
                        >
                          <div
                            class="p-0"
                            style="display: inline-block; font-size: 12px"
                            data-toggle="modal"
                            data-target="#teamInformationPopup"
                          >
                            <div>
                              <span
                                v-if="match.home_Info.yellow_cards > 0"
                                style="
                                  display: inline-block;
                                  padding: 0 1px;
                                  font-size: 9px;
                                  background-color: yellow;
                                "
                              >
                                {{ match.home_Info["yellow_cards"] }}
                              </span>

                              <span
                                v-if="match.home_Info.red_cards > 0"
                                style="
                                  display: inline-block;
                                  padding: 0 1px;
                                  font-size: 9px;
                                  background-color: red;
                                "
                              >
                                {{ match.home_Info["red_cards"] }}
                              </span>

                              {{
                                this.$i18n.locale == "cn"
                                  ? match.home_Info["cn_name"]
                                  : match.home_Info["en_name"]
                              }}
                              <img
                                :src="match.home_Info['logo']"
                                style="width: 20px !important"
                              />
                            </div>
                          </div>
                        </td>

                        <td
                          id="popover-target-score-2320651-1"
                          class="align-middle"
                          style="font-size: 12px !important"
                        >
                          <span
                            data-toggle="modal"
                            data-target="#matchEvent"
                            :style="`color:${scoreColor(match.status_id)};`"
                          >
                            <b v-if="match.status_id == 8">
                              {{
                                match.home_Info.home_score +
                                "-" +
                                match.away_Info.away_score
                              }}
                            </b>

                            <b v-else> - </b>
                          </span>
                        </td>
                        <td
                          class="text-left align-middle"
                          style="width: 250px !important"
                        >
                          <div
                            class="p-0"
                            style="
                              display: inline-block;
                              font-size: 12px;
                              cursor: pointer;
                            "
                            data-toggle="modal"
                            data-target="#teamInformationPopup"
                          >
                            <img
                              :src="match.away_Info.logo"
                              style="width: 20px !important"
                            />
                            <span>
                              {{
                                this.$i18n.locale == "cn"
                                  ? match.away_Info["cn_name"]
                                  : match.away_Info["en_name"]
                              }}
                              <span
                                v-if="match.away_Info.yellow_cards > 0"
                                style="
                                  display: inline-block;
                                  padding: 0 1px;
                                  font-size: 9px;
                                  background-color: yellow;
                                "
                              >
                                {{ match.away_Info["yellow_cards"] }}
                              </span>

                              <span
                                v-if="match.away_Info.red_cards > 0"
                                style="
                                  display: inline-block;
                                  padding: 0 1px;
                                  font-size: 9px;
                                  background-color: red;
                                "
                              >
                                {{ match.away_Info["red_cards"] }}
                              </span>
                            </span>
                          </div>
                        </td>
                        <td class="align-middle" style="font-size: 12px">
                          <div>
                            <span
                              v-if="
                                match.home_Info['half_time_score'] == 0 &&
                                match.away_Info['half_time_score'] == 0
                              "
                            >
                              <b> - </b>
                            </span>
                            <span v-else>
                              {{ match.home_Info["half_time_score"] }}
                              -
                              {{ match.away_Info["half_time_score"] }}
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <center v-if="loading">
                    <p style="font-size: 15px !important">
                      {{ $t("football.live-score.information-is-loading") }}
                      <img
                        src="/images/spinner.gif"
                        alt="spinner"
                        style="width: 12px"
                      />
                    </p>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FOOTER />
</template>
<script>

export default {
  components: {

  },
  data() {
    return {
      matchList: null,
      currentPage: 1,
      loading: false,
      date: null,
      dayClickedBtnVal: null,
      dayNumber: -1,
      hotLeagues: [],
      loaderForSpecificDate: null,
      specificPageNumber: null,
      pagesCnt: null,
      selectedHotLeague: null,
      hotMatches: false,
    };
  },
  created() {
    this.getMatchList(this.dayNumber, this.currentPage);
  },
  mounted() {
    window.addEventListener("scroll", () => {
      // if hotMatches Tab is Selected
      if (this.hotMatches) {
        this.loading = false;
        return "";
      }
      // if live, upcoming or finished Tab is Selected
      else {
        if (this.pagesCnt >= this.currentPage) {
          this.moreMatches = true;
          this.getMatchList(this.dayNumber, this.currentPage);
        } else {
          this.moreMatches = false;
          return "";
        }
      }
    });
  },

  methods: {
    prevDate(daysBefore) {
      // this.dayNumer=daysBefore;
      let current = new Date();
      current.setTime(current.getTime() + daysBefore * 24 * 60 * 60 * 1000); // Subtract 7 days
      return `${current.getFullYear()}-${
        current.getMonth() + 1
      }-${current.getDate()}`;
    },

    nextDate(daysBefore) {
      let current = new Date();
      current.setTime(current.getTime() + daysBefore * 24 * 60 * 60 * 1000); // Subtract 7 days
      return `${current.getFullYear()}-${
        current.getMonth() + 1
      }-${current.getDate()}`;
    },

    getMatchList(day, pageNum) {
      this.dayNumber = day;
      this.dayClickedBtnVal = day;
      this.loaderForSpecificDate = true;
      if (this.loading) return;
      this.loading = true;
      this.$axios
        .get(
          `${this.BASE_SERVER_URI}/api6/football/matchlist/past/${this.dayNumber}?pageNumber=${pageNum}`
        )
        .then((response) => {
          this.matchList = response.data.matchList;
          this.$date = this.matchList[0].match_timing.split(" ")[0];
          this.hotLeagues = response.data.hotLeagues;
          this.pagesCnt = response.data.totalPages;
          this.currentPage = pageNum + 1;
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
          this.loaderForSpecificDate = false;
        });
    },

    searchTeamLeagueName() {
      this.hotMatches = true;
      let val = this.dayNumber;
      this.loading = true;
      let inputkeyword = this.inputLeagueTeamName;
      this.$axios
        .get(
          `${this.BASE_SERVER_URI}/api6/football/matchlist/past/${val}?pagNum=1&locale=${this.$i18n.locale}&inputLeagueTeamName=${inputkeyword}`
        )
        .then((response) => {
          this.matchList = response.data.matchList;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },

    getHotLeagues(id) {
      this.loading = false; // the loading begin
      let val = this.dayNumber;
      this.selectedHotLeague = id;
      this.$axios
        .get(
          `${this.BASE_SERVER_URI}/api6/football/matchlist/past/${val}?pagNum=1&hotLeagueId=${id}`
        )
        .then((response) => {
          this.matchList = response.data.matchList;
          // window.alert(this.matchList );
        })
        .catch((error) => {
          console.log(error);
        }) // code to run on error
        .finally(() => (this.loading = false));
    },
    changeTimeZone(matchTime) {
      matchTime += "+08:00";
      if (this.$i18n.locale == "en") {
        let timeZoneUSA = new Date(matchTime)
          .toLocaleTimeString("en-US", {
            timeZone: "America/New_York",
            hour12: false,
            hour: "2-digit",
            minute: "2-digit",
          })
          .slice(0, 5);
        return timeZoneUSA;
      } else if (this.$i18n.locale == "ty") {
        let timeZoneid = new Date(matchTime)
          .toLocaleTimeString("en-US", {
            timeZone: "Asia/Jakarta",
            hour12: false,
            hour: "2-digit",
            minute: "2-digit",
          })
          .slice(0, 5);
        return timeZoneid;
      } else if (this.$i18n.locale == "vi") {
        let timeZonevi = new Date(matchTime)
          .toLocaleTimeString("en-US", {
            timeZone: "Asia/Vientiane",
            hour12: false,
            hour: "2-digit",
            minute: "2-digit",
          })
          .slice(0, 5);
        return timeZonevi;
      } else if (this.$i18n.locale == "th") {
        var timeZoneth = new Date(matchTime)
          .toLocaleTimeString("en-US", {
            timeZone: "Asia/Bangkok",
            hour12: false,
            hour: "2-digit",
            minute: "2-digit",
          })
          .slice(0, 5);
        return timeZoneth;
      } else
        return new Date(matchTime)
          .toLocaleString([], {
            hour12: false,
            hour: "2-digit",
            minute: "2-digit",
          })
          .slice(0, 5);
    },
    scoreColor(status_code) {
      if (status_code == 8) return "red";
      else return "blue";
    },
  },
};
</script>
<style scoped>
.btn-primary {
  background-color: rgb(52, 144, 220) !important;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .pc-version {
    display: none;
  }

  .mobile-version {
    display: block;
  }
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width: 321px) {
  /* Styles */
  .pc-version {
    display: none;
  }

  .mobile-version {
    display: block;
  }
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 320px) {
  /* Styles */
  .pc-version {
    display: none;
  }

  .mobile-version {
    display: block;
  }
}

/* Tablets, iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .pc-version {
    display: block;
  }

  .mobile-version {
    display: none;
  }
}

/* Tablets, iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .pc-version {
    display: block;
  }

  .mobile-version {
    display: none;
  }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  .pc-version {
    display: block;
  }

  .mobile-version {
    display: none;
  }
}

/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
  .pc-version {
    display: block;
  }

  .mobile-version {
    display: none;
  }
}
</style>
