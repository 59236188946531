<template>
  <div>
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div role="document" class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-primary text-white">
            <h5 id="modal-team-title" class="modal-title">
              <img
                v-if="type == 'football'"
                src="/images/icon-football.png"
                width="38"
                class="p-1"
              />
              <img
                v-if="type == 'basketball'"
                src="/images/icon-basketball.png"
                width="38"
                class="p-1"
              />

              {{ $t("odds.index") }}
            </h5>
            <button
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              class="close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body p-1 m-1">
            <div class="row p-0 m-0 justify-content-md-center">
              <div class="col-12 p-0 m-0">
                <table
                  class="table table-sm table-xs table-hover table-bordered text-center text-wrap custom-mobile table-bet"
                >
                  <tbody>
                    <tr>
                      <td></td>
                    </tr>
                    <tr>
                      <td
                        v-if="matchDetails && matchDetails.league_Info"
                        class="league-title"
                        :style="`background-color:${
                          matchDetails.league_Info &&
                          matchDetails.league_Info.primary_color
                            ? matchDetails.league_Info.primary_color
                            : 'black'
                        };`"
                      >
                        <template
                          v-if="matchDetails && matchDetails.league_Info"
                        >
                          {{
                            this.$i18n.locale == "cn"
                              ? matchDetails.league_Info.cn_name
                              : matchDetails.league_Info.en_name
                          }}
                        </template>
                      </td>
                      <td colspan="12" class="odds-table-match-title">
                        <template v-if="matchDetails && matchDetails.home_Info">
                          {{
                            this.$i18n.locale == "cn"
                              ? matchDetails.home_Info.cn_name
                              : matchDetails.home_Info.en_name
                          }}
                        </template>

                        <span style="color: red"> VS </span>
                        <template v-if="matchDetails && matchDetails.away_Info">
                          {{
                            this.$i18n.locale == "cn"
                              ? matchDetails.away_Info.cn_name
                              : matchDetails.away_Info.en_name
                          }}
                        </template>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="12" class="divider">
                        {{ $t("odds.full") }}
                      </td>
                    </tr>
                    <tr>
                      <td class="odds-type">
                        <span rowspan="2" style="color: yellow"></span>
                      </td>
                      <td rowspan="2" class="odds-table-company-title">
                        <span>{{ $t("odds.company") }} </span>
                      </td>
                      <td
                        colspan="3"
                        class="odds-table-live-title"
                        style="display: none"
                      >
                        {{ $t("odds.on-going") }}
                        <span class="blinking">'</span>
                      </td>
                      <td colspan="3" class="odds-table-live-title">
                        {{ $t("odds.handicap") }}
                      </td>
                      <td colspan="3" class="odds-table-title">
                        {{ $t("odds.euro") }}
                      </td>
                      <td colspan="3" class="odds-table-title">
                        {{ $t("odds.over-under") }}
                      </td>
                    </tr>
                    <tr>
                      <td class="odds-type">
                        {{ $t("odds.handicap") }}
                      </td>
                      <td class="odds-table-live-title" style="display: none">
                        {{ $t("odds.home") }}
                      </td>
                      <td class="odds-table-live-title" style="display: none">
                        {{ $t("odds.handicap") }}
                      </td>
                      <td class="odds-table-live-title" style="display: none">
                        {{ $t("odds.away") }}
                      </td>
                      <td class="odds-table-live-title">
                        {{ $t("odds.home") }}
                      </td>
                      <td class="odds-table-live-title">
                        {{ $t("odds.handicap") }}
                      </td>
                      <td class="odds-table-live-title">
                        {{ $t("odds.away") }}
                      </td>
                      <td class="odds-table-title">
                        {{ $t("odds.big") }}
                      </td>
                      <td class="odds-table-title">
                        {{ $t("odds.handicap") }}
                      </td>
                      <td class="odds-table-title">
                        {{ $t("odds.small") }}
                      </td>
                      <td class="odds-table-title">
                        {{ $t("odds.home") }}
                      </td>
                      <td class="odds-table-title">
                        {{ $t("odds.handicap") }}
                      </td>
                      <td class="odds-table-title">
                        {{ $t("odds.away") }}
                      </td>
                    </tr>
                    <tr v-if="loading">
                      <td colspan="12">
                        <br />
                        <br />
                        <img
                          src="/images/spinner.gif"
                          alt="spinner"
                          style="width: 70px"
                        />
                      </td>
                    </tr>
                    <template v-for="(odds, index) in Odds" :key="index">
                      <!--  -->
                      <template v-if="odds.company == 'BET365'">
                        <tr
                          v-if="
                            (odds.euro && odds.euro.init) ||
                            (odds.asia && odds.asia.init) ||
                            (odds.bigSmall && odds.bigSmall.init)
                          "
                        >
                          <td class="odds-type"></td>
                          {{
                            odds.company
                          }}

                          <!-- begin::asia column -->
                          <td>
                            {{
                              odds.asia && odds.asia.init && odds.asia.init.home
                                ? odds.asia.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.asia &&
                              odds.asia.init &&
                              odds.asia.init.handicap
                                ? odds.asia.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.asia && odds.asia.init && odds.asia.init.away
                                ? odds.asia.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::asia column -->
                          <!-- begin::bigSmall column -->
                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.home
                                ? odds.bigSmall.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.handicap
                                ? odds.bigSmall.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.away
                                ? odds.bigSmall.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::bigSmall Column -->
                          <!-- begin::euro Column -->
                          <td>
                            {{
                              odds.euro && odds.euro.init && odds.euro.init.home
                                ? odds.euro.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.euro &&
                              odds.euro.init &&
                              odds.euro.init.handicap
                                ? odds.euro.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.euro && odds.euro.init && odds.euro.init.away
                                ? odds.euro.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::euro Column -->
                        </tr>
                      </template>
                      <template v-if="odds.company == 'Crown'">
                        <tr
                          v-if="
                            (odds.euro && odds.euro.init) ||
                            (odds.asia && odds.asia.init) ||
                            (odds.bigSmall && odds.bigSmall.init)
                          "
                        >
                          <td class="odds-type"></td>
                          {{
                            odds.company
                          }}

                          <!-- begin::asia column -->
                          <td>
                            {{
                              odds.asia && odds.asia.init && odds.asia.init.home
                                ? odds.asia.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.asia &&
                              odds.asia.init &&
                              odds.asia.init.handicap
                                ? odds.asia.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.asia && odds.asia.init && odds.asia.init.away
                                ? odds.asia.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::asia column -->
                          <!-- begin::bigSmall column -->
                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.home
                                ? odds.bigSmall.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.handicap
                                ? odds.bigSmall.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.away
                                ? odds.bigSmall.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::bigSmall Column -->
                          <!-- begin::euro Column -->
                          <td>
                            {{
                              odds.euro && odds.euro.init && odds.euro.init.home
                                ? odds.euro.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.euro &&
                              odds.euro.init &&
                              odds.euro.init.handicap
                                ? odds.euro.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.euro && odds.euro.init && odds.euro.init.away
                                ? odds.euro.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::euro Column -->
                        </tr>
                      </template>
                      <template v-if="odds.company == '10BET'">
                        <tr
                          v-if="
                            (odds.euro && odds.euro.init) ||
                            (odds.asia && odds.asia.init) ||
                            (odds.bigSmall && odds.bigSmall.init)
                          "
                        >
                          <td class="odds-type"></td>
                          {{
                            odds.company
                          }}

                          <!-- begin::asia column -->
                          <td>
                            {{
                              odds.asia && odds.asia.init && odds.asia.init.home
                                ? odds.asia.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.asia &&
                              odds.asia.init &&
                              odds.asia.init.handicap
                                ? odds.asia.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.asia && odds.asia.init && odds.asia.init.away
                                ? odds.asia.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::asia column -->
                          <!-- begin::bigSmall column -->
                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.home
                                ? odds.bigSmall.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.handicap
                                ? odds.bigSmall.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.away
                                ? odds.bigSmall.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::bigSmall Column -->
                          <!-- begin::euro Column -->
                          <td>
                            {{
                              odds.euro && odds.euro.init && odds.euro.init.home
                                ? odds.euro.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.euro &&
                              odds.euro.init &&
                              odds.euro.init.handicap
                                ? odds.euro.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.euro && odds.euro.init && odds.euro.init.away
                                ? odds.euro.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::euro Column -->
                        </tr>
                      </template>
                      <template v-if="odds.company == 'Ladbrokes'">
                        <tr
                          v-if="
                            (odds.euro && odds.euro.init) ||
                            (odds.asia && odds.asia.init) ||
                            (odds.bigSmall && odds.bigSmall.init)
                          "
                        >
                          <td class="odds-type"></td>
                          {{
                            odds.company
                          }}

                          <!-- begin::asia column -->
                          <td>
                            {{
                              odds.asia && odds.asia.init && odds.asia.init.home
                                ? odds.asia.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.asia &&
                              odds.asia.init &&
                              odds.asia.init.handicap
                                ? odds.asia.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.asia && odds.asia.init && odds.asia.init.away
                                ? odds.asia.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::asia column -->
                          <!-- begin::bigSmall column -->
                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.home
                                ? odds.bigSmall.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.handicap
                                ? odds.bigSmall.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.away
                                ? odds.bigSmall.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::bigSmall Column -->
                          <!-- begin::euro Column -->
                          <td>
                            {{
                              odds.euro && odds.euro.init && odds.euro.init.home
                                ? odds.euro.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.euro &&
                              odds.euro.init &&
                              odds.euro.init.handicap
                                ? odds.euro.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.euro && odds.euro.init && odds.euro.init.away
                                ? odds.euro.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::euro Column -->
                        </tr>
                      </template>
                      <template v-if="odds.company == 'Mansion88'">
                        <tr
                          v-if="
                            (odds.euro && odds.euro.init) ||
                            (odds.asia && odds.asia.init) ||
                            (odds.bigSmall && odds.bigSmall.init)
                          "
                        >
                          <td class="odds-type"></td>
                          {{
                            odds.company
                          }}

                          <!-- begin::asia column -->
                          <td>
                            {{
                              odds.asia && odds.asia.init && odds.asia.init.home
                                ? odds.asia.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.asia &&
                              odds.asia.init &&
                              odds.asia.init.handicap
                                ? odds.asia.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.asia && odds.asia.init && odds.asia.init.away
                                ? odds.asia.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::asia column -->
                          <!-- begin::bigSmall column -->
                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.home
                                ? odds.bigSmall.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.handicap
                                ? odds.bigSmall.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.away
                                ? odds.bigSmall.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::bigSmall Column -->
                          <!-- begin::euro Column -->
                          <td>
                            {{
                              odds.euro && odds.euro.init && odds.euro.init.home
                                ? odds.euro.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.euro &&
                              odds.euro.init &&
                              odds.euro.init.handicap
                                ? odds.euro.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.euro && odds.euro.init && odds.euro.init.away
                                ? odds.euro.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::euro Column -->
                        </tr>
                      </template>
                      <template v-if="odds.company == 'Macauslot'">
                        <tr
                          v-if="
                            (odds.euro && odds.euro.init) ||
                            (odds.asia && odds.asia.init) ||
                            (odds.bigSmall && odds.bigSmall.init)
                          "
                        >
                          <td class="odds-type"></td>
                          {{
                            odds.company
                          }}

                          <!-- begin::asia column -->
                          <td>
                            {{
                              odds.asia && odds.asia.init && odds.asia.init.home
                                ? odds.asia.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.asia &&
                              odds.asia.init &&
                              odds.asia.init.handicap
                                ? odds.asia.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.asia && odds.asia.init && odds.asia.init.away
                                ? odds.asia.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::asia column -->
                          <!-- begin::bigSmall column -->
                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.home
                                ? odds.bigSmall.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.handicap
                                ? odds.bigSmall.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.away
                                ? odds.bigSmall.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::bigSmall Column -->
                          <!-- begin::euro Column -->
                          <td>
                            {{
                              odds.euro && odds.euro.init && odds.euro.init.home
                                ? odds.euro.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.euro &&
                              odds.euro.init &&
                              odds.euro.init.handicap
                                ? odds.euro.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.euro && odds.euro.init && odds.euro.init.away
                                ? odds.euro.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::euro Column -->
                        </tr>
                      </template>
                      <template v-if="odds.company == 'SNAI'">
                        <tr
                          v-if="
                            (odds.euro && odds.euro.init) ||
                            (odds.asia && odds.asia.init) ||
                            (odds.bigSmall && odds.bigSmall.init)
                          "
                        >
                          <td class="odds-type"></td>
                          {{
                            odds.company
                          }}

                          <!-- begin::asia column -->
                          <td>
                            {{
                              odds.asia && odds.asia.init && odds.asia.init.home
                                ? odds.asia.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.asia &&
                              odds.asia.init &&
                              odds.asia.init.handicap
                                ? odds.asia.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.asia && odds.asia.init && odds.asia.init.away
                                ? odds.asia.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::asia column -->
                          <!-- begin::bigSmall column -->
                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.home
                                ? odds.bigSmall.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.handicap
                                ? odds.bigSmall.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.away
                                ? odds.bigSmall.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::bigSmall Column -->
                          <!-- begin::euro Column -->
                          <td>
                            {{
                              odds.euro && odds.euro.init && odds.euro.init.home
                                ? odds.euro.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.euro &&
                              odds.euro.init &&
                              odds.euro.init.handicap
                                ? odds.euro.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.euro && odds.euro.init && odds.euro.init.away
                                ? odds.euro.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::euro Column -->
                        </tr>
                      </template>
                      <template v-if="odds.company == 'William Hill'">
                        <tr
                          v-if="
                            (odds.euro && odds.euro.init) ||
                            (odds.asia && odds.asia.init) ||
                            (odds.bigSmall && odds.bigSmall.init)
                          "
                        >
                          <td class="odds-type"></td>
                          {{
                            odds.company
                          }}

                          <!-- begin::asia column -->
                          <td>
                            {{
                              odds.asia && odds.asia.init && odds.asia.init.home
                                ? odds.asia.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.asia &&
                              odds.asia.init &&
                              odds.asia.init.handicap
                                ? odds.asia.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.asia && odds.asia.init && odds.asia.init.away
                                ? odds.asia.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::asia column -->
                          <!-- begin::bigSmall column -->
                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.home
                                ? odds.bigSmall.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.handicap
                                ? odds.bigSmall.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.away
                                ? odds.bigSmall.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::bigSmall Column -->
                          <!-- begin::euro Column -->
                          <td>
                            {{
                              odds.euro && odds.euro.init && odds.euro.init.home
                                ? odds.euro.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.euro &&
                              odds.euro.init &&
                              odds.euro.init.handicap
                                ? odds.euro.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.euro && odds.euro.init && odds.euro.init.away
                                ? odds.euro.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::euro Column -->
                        </tr>
                      </template>
                      <template v-if="odds.company == 'Easybets'">
                        <tr
                          v-if="
                            (odds.euro && odds.euro.init) ||
                            (odds.asia && odds.asia.init) ||
                            (odds.bigSmall && odds.bigSmall.init)
                          "
                        >
                          <td class="odds-type"></td>
                          {{
                            odds.company
                          }}

                          <!-- begin::asia column -->
                          <td>
                            {{
                              odds.asia && odds.asia.init && odds.asia.init.home
                                ? odds.asia.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.asia &&
                              odds.asia.init &&
                              odds.asia.init.handicap
                                ? odds.asia.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.asia && odds.asia.init && odds.asia.init.away
                                ? odds.asia.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::asia column -->
                          <!-- begin::bigSmall column -->
                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.home
                                ? odds.bigSmall.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.handicap
                                ? odds.bigSmall.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.away
                                ? odds.bigSmall.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::bigSmall Column -->
                          <!-- begin::euro Column -->
                          <td>
                            {{
                              odds.euro && odds.euro.init && odds.euro.init.home
                                ? odds.euro.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.euro &&
                              odds.euro.init &&
                              odds.euro.init.handicap
                                ? odds.euro.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.euro && odds.euro.init && odds.euro.init.away
                                ? odds.euro.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::euro Column -->
                        </tr>
                      </template>
                      <template v-if="odds.company == 'Vcbet'">
                        <tr
                          v-if="
                            (odds.euro && odds.euro.init) ||
                            (odds.asia && odds.asia.init) ||
                            (odds.bigSmall && odds.bigSmall.init)
                          "
                        >
                          <td class="odds-type"></td>
                          {{
                            odds.company
                          }}

                          <!-- begin::asia column -->
                          <td>
                            {{
                              odds.asia && odds.asia.init && odds.asia.init.home
                                ? odds.asia.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.asia &&
                              odds.asia.init &&
                              odds.asia.init.handicap
                                ? odds.asia.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.asia && odds.asia.init && odds.asia.init.away
                                ? odds.asia.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::asia column -->
                          <!-- begin::bigSmall column -->
                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.home
                                ? odds.bigSmall.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.handicap
                                ? odds.bigSmall.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.away
                                ? odds.bigSmall.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::bigSmall Column -->
                          <!-- begin::euro Column -->
                          <td>
                            {{
                              odds.euro && odds.euro.init && odds.euro.init.home
                                ? odds.euro.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.euro &&
                              odds.euro.init &&
                              odds.euro.init.handicap
                                ? odds.euro.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.euro && odds.euro.init && odds.euro.init.away
                                ? odds.euro.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::euro Column -->
                        </tr>
                      </template>
                      <template v-if="odds.company == 'EuroBet'">
                        <tr
                          v-if="
                            (odds.euro && odds.euro.init) ||
                            (odds.asia && odds.asia.init) ||
                            (odds.bigSmall && odds.bigSmall.init)
                          "
                        >
                          <td class="odds-type"></td>
                          {{
                            odds.company
                          }}

                          <!-- begin::asia column -->
                          <td>
                            {{
                              odds.asia && odds.asia.init && odds.asia.init.home
                                ? odds.asia.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.asia &&
                              odds.asia.init &&
                              odds.asia.init.handicap
                                ? odds.asia.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.asia && odds.asia.init && odds.asia.init.away
                                ? odds.asia.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::asia column -->
                          <!-- begin::bigSmall column -->
                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.home
                                ? odds.bigSmall.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.handicap
                                ? odds.bigSmall.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.away
                                ? odds.bigSmall.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::bigSmall Column -->
                          <!-- begin::euro Column -->
                          <td>
                            {{
                              odds.euro && odds.euro.init && odds.euro.init.home
                                ? odds.euro.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.euro &&
                              odds.euro.init &&
                              odds.euro.init.handicap
                                ? odds.euro.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.euro && odds.euro.init && odds.euro.init.away
                                ? odds.euro.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::euro Column -->
                        </tr>
                      </template>
                      <template v-if="odds.company == 'Interwetten'">
                        <tr
                          v-if="
                            (odds.euro && odds.euro.init) ||
                            (odds.asia && odds.asia.init) ||
                            (odds.bigSmall && odds.bigSmall.init)
                          "
                        >
                          <td class="odds-type"></td>
                          {{
                            odds.company
                          }}

                          <!-- begin::asia column -->
                          <td>
                            {{
                              odds.asia && odds.asia.init && odds.asia.init.home
                                ? odds.asia.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.asia &&
                              odds.asia.init &&
                              odds.asia.init.handicap
                                ? odds.asia.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.asia && odds.asia.init && odds.asia.init.away
                                ? odds.asia.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::asia column -->
                          <!-- begin::bigSmall column -->
                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.home
                                ? odds.bigSmall.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.handicap
                                ? odds.bigSmall.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.away
                                ? odds.bigSmall.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::bigSmall Column -->
                          <!-- begin::euro Column -->
                          <td>
                            {{
                              odds.euro && odds.euro.init && odds.euro.init.home
                                ? odds.euro.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.euro &&
                              odds.euro.init &&
                              odds.euro.init.handicap
                                ? odds.euro.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.euro && odds.euro.init && odds.euro.init.away
                                ? odds.euro.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::euro Column -->
                        </tr>
                      </template>
                      <template v-if="odds.company == '12bet'">
                        <tr
                          v-if="
                            (odds.euro && odds.euro.init) ||
                            (odds.asia && odds.asia.init) ||
                            (odds.bigSmall && odds.bigSmall.init)
                          "
                        >
                          <td class="odds-type"></td>
                          {{
                            odds.company
                          }}

                          <!-- begin::asia column -->
                          <td>
                            {{
                              odds.asia && odds.asia.init && odds.asia.init.home
                                ? odds.asia.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.asia &&
                              odds.asia.init &&
                              odds.asia.init.handicap
                                ? odds.asia.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.asia && odds.asia.init && odds.asia.init.away
                                ? odds.asia.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::asia column -->
                          <!-- begin::bigSmall column -->
                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.home
                                ? odds.bigSmall.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.handicap
                                ? odds.bigSmall.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.away
                                ? odds.bigSmall.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::bigSmall Column -->
                          <!-- begin::euro Column -->
                          <td>
                            {{
                              odds.euro && odds.euro.init && odds.euro.init.home
                                ? odds.euro.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.euro &&
                              odds.euro.init &&
                              odds.euro.init.handicap
                                ? odds.euro.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.euro && odds.euro.init && odds.euro.init.away
                                ? odds.euro.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::euro Column -->
                        </tr>
                      </template>
                      <template v-if="odds.company == 'Sbobet'">
                        <tr
                          v-if="
                            (odds.euro && odds.euro.init) ||
                            (odds.asia && odds.asia.init) ||
                            (odds.bigSmall && odds.bigSmall.init)
                          "
                        >
                          <td class="odds-type"></td>
                          {{
                            odds.company
                          }}

                          <!-- begin::asia column -->
                          <td>
                            {{
                              odds.asia && odds.asia.init && odds.asia.init.home
                                ? odds.asia.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.asia &&
                              odds.asia.init &&
                              odds.asia.init.handicap
                                ? odds.asia.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.asia && odds.asia.init && odds.asia.init.away
                                ? odds.asia.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::asia column -->
                          <!-- begin::bigSmall column -->
                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.home
                                ? odds.bigSmall.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.handicap
                                ? odds.bigSmall.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.away
                                ? odds.bigSmall.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::bigSmall Column -->
                          <!-- begin::euro Column -->
                          <td>
                            {{
                              odds.euro && odds.euro.init && odds.euro.init.home
                                ? odds.euro.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.euro &&
                              odds.euro.init &&
                              odds.euro.init.handicap
                                ? odds.euro.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.euro && odds.euro.init && odds.euro.init.away
                                ? odds.euro.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::euro Column -->
                        </tr>
                      </template>
                      <template v-if="odds.company == 'Wewbet'">
                        <tr
                          v-if="
                            (odds.euro && odds.euro.init) ||
                            (odds.asia && odds.asia.init) ||
                            (odds.bigSmall && odds.bigSmall.init)
                          "
                        >
                          <td class="odds-type"></td>
                          {{
                            odds.company
                          }}

                          <!-- begin::asia column -->
                          <td>
                            {{
                              odds.asia && odds.asia.init && odds.asia.init.home
                                ? odds.asia.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.asia &&
                              odds.asia.init &&
                              odds.asia.init.handicap
                                ? odds.asia.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.asia && odds.asia.init && odds.asia.init.away
                                ? odds.asia.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::asia column -->
                          <!-- begin::bigSmall column -->
                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.home
                                ? odds.bigSmall.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.handicap
                                ? odds.bigSmall.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.away
                                ? odds.bigSmall.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::bigSmall Column -->
                          <!-- begin::euro Column -->
                          <td>
                            {{
                              odds.euro && odds.euro.init && odds.euro.init.home
                                ? odds.euro.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.euro &&
                              odds.euro.init &&
                              odds.euro.init.handicap
                                ? odds.euro.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.euro && odds.euro.init && odds.euro.init.away
                                ? odds.euro.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::euro Column -->
                        </tr>
                      </template>
                      <template v-if="odds.company == '18Bet'">
                        <tr
                          v-if="
                            (odds.euro && odds.euro.init) ||
                            (odds.asia && odds.asia.init) ||
                            (odds.bigSmall && odds.bigSmall.init)
                          "
                        >
                          <td class="odds-type"></td>
                          {{
                            odds.company
                          }}

                          <!-- begin::asia column -->
                          <td>
                            {{
                              odds.asia && odds.asia.init && odds.asia.init.home
                                ? odds.asia.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.asia &&
                              odds.asia.init &&
                              odds.asia.init.handicap
                                ? odds.asia.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.asia && odds.asia.init && odds.asia.init.away
                                ? odds.asia.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::asia column -->
                          <!-- begin::bigSmall column -->
                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.home
                                ? odds.bigSmall.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.handicap
                                ? odds.bigSmall.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.away
                                ? odds.bigSmall.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::bigSmall Column -->
                          <!-- begin::euro Column -->
                          <td>
                            {{
                              odds.euro && odds.euro.init && odds.euro.init.home
                                ? odds.euro.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.euro &&
                              odds.euro.init &&
                              odds.euro.init.handicap
                                ? odds.euro.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.euro && odds.euro.init && odds.euro.init.away
                                ? odds.euro.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::euro Column -->
                        </tr>
                      </template>
                      <template v-if="odds.company == 'Fun88'">
                        <tr
                          v-if="
                            (odds.euro && odds.euro.init) ||
                            (odds.asia && odds.asia.init) ||
                            (odds.bigSmall && odds.bigSmall.init)
                          "
                        >
                          <td class="odds-type"></td>
                          {{
                            odds.company
                          }}

                          <!-- begin::asia column -->
                          <td>
                            {{
                              odds.asia && odds.asia.init && odds.asia.init.home
                                ? odds.asia.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.asia &&
                              odds.asia.init &&
                              odds.asia.init.handicap
                                ? odds.asia.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.asia && odds.asia.init && odds.asia.init.away
                                ? odds.asia.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::asia column -->
                          <!-- begin::bigSmall column -->
                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.home
                                ? odds.bigSmall.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.handicap
                                ? odds.bigSmall.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.away
                                ? odds.bigSmall.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::bigSmall Column -->
                          <!-- begin::euro Column -->
                          <td>
                            {{
                              odds.euro && odds.euro.init && odds.euro.init.home
                                ? odds.euro.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.euro &&
                              odds.euro.init &&
                              odds.euro.init.handicap
                                ? odds.euro.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.euro && odds.euro.init && odds.euro.init.away
                                ? odds.euro.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::euro Column -->
                        </tr>
                      </template>
                      <template v-if="odds.company == '188bet'">
                        <tr
                          v-if="
                            (odds.euro && odds.euro.init) ||
                            (odds.asia && odds.asia.init) ||
                            (odds.bigSmall && odds.bigSmall.init)
                          "
                        >
                          <td class="odds-type"></td>
                          {{
                            odds.company
                          }}

                          <!-- begin::asia column -->
                          <td>
                            {{
                              odds.asia && odds.asia.init && odds.asia.init.home
                                ? odds.asia.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.asia &&
                              odds.asia.init &&
                              odds.asia.init.handicap
                                ? odds.asia.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.asia && odds.asia.init && odds.asia.init.away
                                ? odds.asia.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::asia column -->
                          <!-- begin::bigSmall column -->
                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.home
                                ? odds.bigSmall.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.handicap
                                ? odds.bigSmall.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.away
                                ? odds.bigSmall.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::bigSmall Column -->
                          <!-- begin::euro Column -->
                          <td>
                            {{
                              odds.euro && odds.euro.init && odds.euro.init.home
                                ? odds.euro.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.euro &&
                              odds.euro.init &&
                              odds.euro.init.handicap
                                ? odds.euro.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.euro && odds.euro.init && odds.euro.init.away
                                ? odds.euro.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::euro Column -->
                        </tr>
                      </template>
                      <template v-if="odds.company == 'Pinnacle'">
                        <tr
                          v-if="
                            (odds.euro && odds.euro.init) ||
                            (odds.asia && odds.asia.init) ||
                            (odds.bigSmall && odds.bigSmall.init)
                          "
                        >
                          <td class="odds-type"></td>
                          {{
                            odds.company
                          }}

                          <!-- begin::asia column -->
                          <td>
                            {{
                              odds.asia && odds.asia.init && odds.asia.init.home
                                ? odds.asia.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.asia &&
                              odds.asia.init &&
                              odds.asia.init.handicap
                                ? odds.asia.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.asia && odds.asia.init && odds.asia.init.away
                                ? odds.asia.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::asia column -->
                          <!-- begin::bigSmall column -->
                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.home
                                ? odds.bigSmall.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.handicap
                                ? odds.bigSmall.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.bigSmall &&
                              odds.bigSmall.init &&
                              odds.bigSmall.init.away
                                ? odds.bigSmall.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::bigSmall Column -->
                          <!-- begin::euro Column -->
                          <td>
                            {{
                              odds.euro && odds.euro.init && odds.euro.init.home
                                ? odds.euro.init.home
                                : "-"
                            }}
                          </td>
                          <td>
                            {{
                              odds.euro &&
                              odds.euro.init &&
                              odds.euro.init.handicap
                                ? odds.euro.init.handicap
                                : "-"
                            }}
                          </td>

                          <td>
                            {{
                              odds.euro && odds.euro.init && odds.euro.init.away
                                ? odds.euro.init.away
                                : "-"
                            }}
                          </td>
                          <!-- end::euro Column -->
                        </tr>
                      </template>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-secondary"
            >
              {{ $t("football.live-score.close") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: {
    matchId: {
      type: Object,
      default: null,
    },
    matchDetails: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
  },
  mounted() {
    let that = this;
    window.$("#exampleModal").on("shown.bs.modal", function () {
      that.Odds = null;
      console.log("Showw");
      that.getMatchOdds();
    });

    window.$("#exampleModal").on("hidden.bs.modal", function () {
      that.Odds = null;
    });
  },

  data() {
    return {
      matchList: null,
      Odds: null,
      loading: false,
    };
  },
  methods: {
    getMatchOdds() {
      this.loading = true;
      axios
        .get(
          `${this.BASE_SERVER_URI}/api/${this.type}/odds/matchOdds/${this.matchId}`
        )
        .then((response) => {
          this.Odds = response.data["Company"];
          console.log("-----------> Match Odds", this.Odds["Company"]);
          this.loading = false;
        })
        .catch(() => {
          console.log("------> Match Odds Error");
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
<style scoped>
.blinking {
  animation: blinkingText 0.8s infinite;
}

@keyframes blinkingText {
  0% {
    color: red;
  }

  49% {
    color: red;
  }

  60% {
    color: transparent;
  }

  99% {
    color: transparent;
  }

  100% {
    color: red;
  }
}

.table-bet {
  zoom: 0.75;
  cursor: pointer;
}

.divider {
  height: 30px;
  background-color: #f7be57;
  color: white;
}

.league-title {
  color: white;
}

.odds-table-company-title {
  background-color: white;
}

.odds-table-match-title {
  background-color: lightyellow;
}

.odds-table-live-title {
  background-color: aliceblue;
}

.odds-table-title {
  background-color: lightgrey;
}

.odds-type {
  background-color: cornflowerblue;
  color: white;
  font-weight: bold;
}

.player-detail-list {
  padding: 0;
  margin: 0;
  border: solid 0px #000;
}

.player-detail-list label {
  padding: 0;
  margin: 0;
  padding: 2px 4px 2px 4px;
}

.player-detail-list div {
  padding: 0;
  margin: 0;
  padding: 2px 4px 2px 4px;
}

@media (max-width: 450px) {
  .custom-mobile {
    font-size: 12px;
    zoom: 0.7;
  }

  .table th,
  .table td {
    padding: 0px;
  }
}
</style>
