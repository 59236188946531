<template>
  <br />
  <div class="container-fluid p-0">
    <div class="row p-0 m-0">
      <div class="col-md-12 m-0 p-0">
        <div class="row justify-content-md-center p-0 m-0 mt-1 mb-1"></div>
        <div style="padding: 0px 15px">
          <div class="wrap">
            <div class="b-overlay-wrap position-relative">
              <!-- begin::top buttons -->
              <div class="text-center pb-3">
                <button
                  lg="2"
                  type="button"
                  v-on:click="activefoobtallData()"
                  :class="
                    football
                      ? 'btn mr-2 btn-primary btn-lg rounded-pill'
                      : 'mr-2 btn btn-outline-secondary btn-lg rounded-pill'
                  "
                >
                  <!--  -->
                  {{ $t("common.menu.football") }}
                </button>

                <!-- -->
                <button
                  v-on:click="activeBasketballData()"
                  lg="2"
                  type="button"
                  :class="
                    basketball
                      ? 'btn mr-2 btn-primary btn-lg rounded-pill'
                      : 'mr-2 btn btn-outline-secondary btn-lg rounded-pill'
                  "
                >
                  {{ $t("common.menu.basketball") }}
                </button>
              </div>
              <!-- end::top buttons -->

              <!-- begin::football section -->
              <div class="card" v-if="football && !loading">
                <div class="tabs">
                  <div class="card-header bg-dark">
                    <ul
                      role="tablist"
                      class="nav nav-pills card-header-pills small nav-tab-custom"
                    >
                      <!-- begin::league list -->
                      <span
                        v-for="(league, index) in footballLeagueList"
                        :key="league.id"
                      >
                        <li role="presentation" class="nav-item">
                          <a
                            v-on:click="
                              getFootballStandings(league.id, league.nameEn)
                            "
                            style="color: white"
                            role="tab"
                            aria-selected="true"
                            aria-setsize="15"
                            aria-posinset="1"
                            href="#"
                            target="_self"
                            class="nav-link"
                            :class="
                              footballActive == league.id && index < 1
                                ? 'active'
                                : ''
                            "
                          >
                            <span>
                              {{
                                this.$i18n.locale == "cn"
                                  ? league.nameCn
                                  : league.nameEn
                              }}
                            </span>
                          </a>
                        </li>
                      </span>
                      <!-- end::league least -->
                    </ul>
                  </div>
                  <div class="tab-content">
                    <div
                      role="tabpanel"
                      aria-hidden="false"
                      class="tab-pane active card-body"
                    >
                      <!-- begin::active league -->

                      <!-- end::active league -->
                      <!-- begin::table data -->
                      <div class="standing-table">
                        <table
                          id="div_Table1"
                          width="90%"
                          cellpadding="2"
                          cellspacing="0"
                          bgcolor="#FFFFFF"
                          class="table table-hover"
                          style="margin-bottom: 8px"
                        >
                          <thead>
                            <tr align="center" class="th-bg">
                              <td width="10">{{ $t("standings.rank") }}</td>
                              <td
                                height="18"
                                align="left"
                                style="padding-left: 8px"
                              >
                                {{ $t("standings.team-name") }}
                              </td>
                              <td width="32">
                                {{ $t("standings.match-played") }}
                              </td>
                              <td width="32">{{ $t("standings.win") }}</td>
                              <td width="32">{{ $t("standings.draw") }}</td>
                              <td width="32">{{ $t("standings.loss") }}</td>
                              <td width="32">
                                {{ $t("standings.goals-for") }}
                              </td>
                              <td width="32">
                                {{ $t("standings.goals-against") }}
                              </td>
                              <td width="32">
                                {{ $t("standings.goals-draw") }}
                              </td>
                              <td width="28">{{ $t("standings.points") }}</td>
                            </tr>
                          </thead>

                          <tbody
                            v-for="league in footballStandingsData"
                            :key="league.id"
                          >
                            <tr v-if="footballStandingsData.length > 1">
                              <td colspan="10" class="bg-dark text-white">
                                <b>GROUP {{ league.group }}</b>
                              </td>
                            </tr>
                            <tr
                              align="center"
                              v-for="team in league.rows"
                              :key="team.id"
                            >
                              <td>
                                <span class="rank-display">
                                  {{ team.position }}
                                </span>
                              </td>
                              <td align="left" style="padding-left: 8px">
                                <img
                                  :src="team.teamInfo.logo"
                                  alt=""
                                  class="flag-sm"
                                />

                                &nbsp;
                                <span>
                                  {{
                                    this.$i18n.locale == "cn"
                                      ? team.teamInfo.cn_name
                                      : team.teamInfo.name
                                  }}
                                </span>
                              </td>

                              <td>{{ team.total }}</td>
                              <td>{{ team.won }}</td>
                              <td>{{ team.draw }}</td>
                              <td>{{ team.loss }}</td>
                              <td>{{ team.deduct_points }}</td>
                              <td>{{ team.goals }}</td>
                              <td>{{ team.goals_against }}</td>
                              <td>{{ team.goal_diff }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <!-- end::table data -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- begin::Basketball Section -->
              <div class="card" v-if="basketball && !loading">
                <div class="tabs">
                  <div class="card-header bg-dark">
                    <ul
                      role="tablist"
                      class="nav nav-pills card-header-pills small nav-tab-custom"
                    >
                      <!-- begin::league list -->
                      <span
                        v-for="(league, index) in basketballLeagueList"
                        :key="league.id"
                      >
                        <li role="presentation" class="nav-item">
                          <a
                            v-on:click="
                              getBasketballStandings(league.id, league.nameEn)
                            "
                            style="color: white"
                            role="tab"
                            aria-selected="true"
                            aria-setsize="15"
                            aria-posinset="1"
                            href="#"
                            target="_self"
                            class="nav-link"
                            :class="
                              basketballActive == league.id && index < 1
                                ? 'active'
                                : ''
                            "
                          >
                            <span>
                              {{
                                this.$i18n.locale == "cn"
                                  ? league.nameCn
                                  : league.nameEn
                              }}
                            </span>
                          </a>
                        </li>
                      </span>
                      <!-- end::league least -->
                    </ul>
                  </div>
                  <div class="tab-content">
                    <div
                      role="tabpanel"
                      aria-hidden="false"
                      class="tab-pane active card-body"
                    >
                      <!-- begin::active league -->

                      <!-- end::active league -->
                      <!-- begin::table data -->
                      <div class="standing-table">
                        <table
                          id="div_Table1"
                          width="90%"
                          cellpadding="2"
                          cellspacing="0"
                          bgcolor="#FFFFFF"
                          class="table table-hover"
                          style="margin-bottom: 8px"
                        >
                          <thead>
                            <tr align="center" class="th-bg">
                              <td width="10">{{ $t("standings.rank") }}</td>
                              <td
                                height="18"
                                align="left"
                                style="padding-left: 8px"
                              >
                                {{ $t("standings.team-name") }}
                              </td>
                              <td width="32">{{ $t("standings.win") }}</td>
                              <td width="32">{{ $t("standings.loss") }}</td>
                              <td width="32">{{ $t("standings.home") }}</td>
                              <td width="32">{{ $t("standings.away") }}</td>
                              <td width="32">{{ $t("standings.last-ten") }}</td>
                            </tr>
                          </thead>

                          <tbody
                            v-for="league in basketballStandingsData"
                            :key="league.id"
                          >
                            <tr v-if="basketballStandingsData.length > 1">
                              <td colspan="10" class="bg-dark text-white">
                                <b>GROUP {{ league.name }}</b>
                              </td>
                            </tr>
                            <tr
                              align="center"
                              v-for="team in league.rows"
                              :key="team.id"
                            >
                              <td>
                                <span class="rank-display">
                                  {{ team.position }}
                                </span>
                              </td>
                              <td align="left" style="padding-left: 8px">
                                <img
                                  :src="team.teamInfo.logo"
                                  alt=""
                                  class="flag-sm"
                                />

                                &nbsp;
                                <span>
                                  {{
                                    this.$i18n.locale == "cn"
                                      ? team.teamInfo.cn_name
                                      : team.teamInfo.name
                                  }}
                                </span>
                              </td>
                              <td>{{ team.won }}</td>
                              <td>{{ team.lost }}</td>
                              <td>{{ team.home }}</td>
                              <td>{{ team.away }}</td>
                              <td>{{ team.last_10 }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <!-- end::table data -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- end::Basketball Section -->
              <!-- begin::loading section -->
              <center v-if="loading">
                <p style="font-size: 10px !important">
                  {{ $t("football.live-score.information-is-loading") }}
                  <img
                    src="/images/spinner.gif"
                    alt="spinner"
                    style="width: 10px"
                  />
                </p>
              </center>
              <!-- end::loading section -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  components: {

  },
  data() {
    return {
      loading: false,
      football: true,
      footballActive: "jednm9whz0ryox8",
      footballStandingsData: null,
      footballLeagueList: null,
      footballActiveName: "English  Premier League",

      basketball: false,
      basketballActive: "49vjxm8xt4q6odg",
      basketballStandingsData: null,
      basketballLeagueList: null,
      basketballActiveName: null,
    };
  },
  mounted() {
    console.log("This Component is mounted Successfully...!");
  },
  created() {
    this.getFootballStandings(this.footballActive);
    this.getBasketballLeagueList();
    this.getBasketballStandings(this.basketballActive);
  },

  methods: {
    getFootballStandings(leagueId) {
      this.football = true;
      this.loading = true;
      this.footballActive = leagueId;
      this.getFootballLeagueList();
      this.$axios
        .get(
          `${this.BASE_SERVER_URI}/api6/football/standings/${this.footballActive}`
        )
        .then((response) => {
          this.footballStandingsData = response.data.standingsData;
          this.loading = false;
        });
    },
    getFootballLeagueList() {
      this.$axios
        .get(
          `${this.BASE_SERVER_URI}/api6/football/leagues-list-mobile/${this.footballActive}`
        )
        .then((response) => {
          this.footballLeagueList = response.data.leagueList;
          this.loading = false;
        });
    },

    getBasketballStandings(leagueId) {
      this.loading = true;
      this.basketballActive = leagueId;
      this.getBasketballLeagueList();
      this.$axios
        .get(
          `${this.BASE_SERVER_URI}/api6/basketball/standings/${this.basketballActive}`
        )
        .then((response) => {
          this.basketballStandingsData = response.data.standingsData;
          this.loading = false;
        });
    },

    getBasketballLeagueList() {
      this.$axios
        .get(
          `${this.BASE_SERVER_URI}/api6/basketball/leagues-list/${this.basketballActive}`
        )
        .then((response) => {
          this.basketballLeagueList = response.data.leagueList;
          this.loading = false;
        });
    },

    calculatePercentage(currentValue, totalVale) {
      return Math.round((currentValue * 100) / totalVale);
    },
    activefoobtallData() {
      this.basketball = false;
      this.football = true;
    },
    activeBasketballData() {
      this.basketball = true;
      this.football = false;
    },
  },
};
</script>




  
<style scoped>
.tab-panel {
  flex-wrap: nowrap;
}
.red-win {
  color: red !important;
}
.green-lose {
  color: #538324 !important;
}
.wrap {
  overflow: hidden;
  width: 100%;
  flex-direction: row;
}

.nav-pills {
  flex-wrap: nowrap;
  white-space: nowrap;
  height: 45px;
  overflow: auto;
}
.icon-display {
  display: inline-block;
}
.rank-display {
  display: inline-block;
  width: 5px;
  margin-right: 5px;
  margin-left: 2px;
}
.h-Grey {
  background-color: #e7e7e7;
  line-height: 30px !important;
  color: #333;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  padding-left: 10px;
}
.h-Orange {
  background-color: rgb(253, 252, 204) !important;
}
.h-Default {
  background-color: #0070ca !important;
  color: #ffffff;
  text-align: left;
  height: 55px;
  font-size: 14px;
  line-height: 45px;
  font-weight: 600;
  padding-left: 10px;
}

.standing-table {
  overflow: auto;
  zoom: 0.85 !important;
}
.title_tr {
  background-color: rgba(0, 0, 0, 0.05) !important;
  font-weight: 500;
}
#div_Table1 > tbody > tr.th-bg {
  background-color: rgba(0, 0, 0, 0.05) !important;
  font-weight: 500;
}
.table td {
  white-space: nowrap !important;
}
.league-title {
  font-size: 12px;
}
.league-logo {
  width: 35px;
  height: 35px;
  margin-right: 15px;
}
.table-title {
  padding-left: 15px;
  height: 50px;
  font-size: 12px;
  font-weight: bold;
  line-height: 50px;
  color: white;
}
table {
  font-size: 10px !important;
}

.table th,
.table td {
  padding: 5px;
  vertical-align: bottom;
}

.icon-xs {
  width: 15px;
  height: 15px;
}
.flag-sm {
  width: 15px;
  height: 15px;
}
.east > div,
.west > div {
  height: 50px;
  color: #ffffff;
  font-size: 14px;
  line-height: 50px;
  padding-left: 20px;
  background: #49a6fc;
}

.west > div {
  background: #ec6a86;
}
.icon-display {
  display: inline-block;
}
.rank-display {
  display: inline-block;
  width: 5px;
  margin-right: 5px;
  margin-left: 2px;
}
.h-Grey {
  background-color: #e7e7e7;
  line-height: 30px !important;
  color: #333;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  padding-left: 10px;
}
.h-Orange {
  background-color: rgb(253, 252, 204) !important;
}
.h-Default {
  background-color: #0070ca !important;
  color: #ffffff;
  text-align: left;
  height: 55px;
  font-size: 14px;
  line-height: 45px;
  font-weight: 600;
  padding-left: 10px;
}

.standing-table {
  overflow: auto;
  zoom: 0.85 !important;
}
.title_tr {
  background-color: rgba(0, 0, 0, 0.05) !important;
  font-weight: 500;
}
#div_Table1 > tbody > tr.th-bg {
  background-color: rgba(0, 0, 0, 0.05) !important;
  font-weight: 500;
}
.table td {
  white-space: nowrap !important;
}
.league-title {
  font-size: 12px;
}
.league-logo {
  width: 35px;
  height: 35px;
  margin-right: 15px;
}
.table-title {
  padding-left: 15px;
  height: 50px;
  font-size: 12px;
  font-weight: bold;
  line-height: 50px;
  color: white;
}
table {
  font-size: 10px !important;
}

.table th,
.table td {
  padding: 5px;
  vertical-align: bottom;
}

.icon-xs {
  width: 15px;
  height: 15px;
}
.flag-sm {
  width: 15px;
  height: 15px;
}
.east > div,
.west > div {
  height: 50px;
  color: #ffffff;
  font-size: 14px;
  line-height: 50px;
  padding-left: 20px;
  background: #49a6fc;
}

.west > div {
  background: #ec6a86;
}
</style>
