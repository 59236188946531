<template>
  <main
    class="main"
    style="
      background: url(https://www.77577.live/assets/page/bg-pc.jpg);
      background-size: cover;
      background-position: center center;
      width: 100%;
      overflow: hidden;
    "
  >
    <!-- begin::Mobile Version -->
    <div
      class="container d-block d-lg-none"
      style="background-color: transparent; overflow: hidden"
    >
      <div class="text-center p-0 m-0" style="border: solid 0px #fff">
        <img
          src="/images/page/logo-app-77577.png"
          style="width: 15%; padding: 10px 0 0 0"
          class="img-fluid"
        />
        <div>
          <img
            :src="
              '/images/page/download-content-mobile-' +
              this.$i18n.locale +
              '-20220724.png'
            "
            style="width: 90%; padding: 10px 0 20px 0"
            class="img-fluid"
          />
        </div>
        <div class="row p-0 m-0 mb-5">
          <div class="col-6">
            <img
              id="badge-apple"
              src="/images/page/badge-app-store.png"
              class="img-fluid"
              style="width: 70%; margin-bottom: 10px"
            />
          </div>
          <div class="col-6">
            <a
              style="color: white"
              href="#ios-installation-guide"
              class="apple-install-guide"
              >* {{ $t("download-app.installation-guide") }}</a
            >
          </div>

          <div class="col-6">
            <a
              id="donwload-url-lite"
              href="https://j3sr4.ozlomhd.com/ua290"
              class="btn btn-lg btn-primary"
              ><b>{{ $t("download-app.lite-version") }}</b></a
            >
          </div>
          <div class="col-6">
            <a
              href="https://j3sr4.ozlomhd.com/ua290"
              id="donwload-url-full"
              class="btn btn-lg btn-primary"
              ><b>{{ $t("download-app.full-version") }}</b></a
            >
          </div>

          <div
            class="col-12"
            style="margin-top: 70px !important; margin-left: -100px !important"
          >
            <b style="color: white; font-size: 20px !important"
              >* {{ $t("download-app.full-version") }}</b
            >
          </div>

          <div class="col-12" style="margin-left: -80px !important">
            <a id="ios-installation-guide"
              ><b style="color: white; font-size: 20px !important">{{
                $t("download-app.installation-guide")
              }}</b></a
            >
          </div>
          <swiper
            :slides-per-view="2"
            :space-between="50"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
            :style="'margin-top: 10px !important'"
          >
            <swiper-slide v-for="n of 14" :key="n">
              <img
                :src="
                  '/images/page/installation-guide/' +
                  this.$i18n.locale +
                  '/' +
                  n +
                  '.jpg'
                "
                class="img-fluid"
              />
            </swiper-slide>
          </swiper>
        </div>
        <hr />
      </div>
    </div>
    <!-- end::Mobile Version -->
    <!-- begin::Desktop Version -->
    <div
      class="container d-none d-lg-block"
      style="background-color: transparent; height: 700px !important"
    >
      <div class="">
        <div class="row">
          <div class="col-lg-6"></div>
          <div class="col-lg-6 text-right mt-3">
            <a
              style="font-size: 18px"
              :href="'/' + this.$i18n.locale + '/live/football'"
              >{{ $t("odds.home") }}</a
            >
            <a
              style="
                font-size: 18px;
                margin-left: 30px;
                margin-right: 50px;
                text-decoration: underline;
                text-underline-position: under;
                color: #2233f1;
              "
            >
              {{ $t("common.menu.download-app") }}</a
            >
          </div>

          <div class="col-lg-8" style="margin-top: 30px">
            <img
              :src="
                '/images/page/download-content-01-' +
                this.$i18n.locale +
                '-20220614.png'
              "
              class="img-fluid"
            />
            <!-- this.$i18n.locale -->
          </div>
          <div class="col-lg-4" style="margin-top: 30px">
            <img
              src="/images/page/2fb1c22701574cc5d68b99cffc48dbe8.png"
              style="width: 200px; margin-left: 60px; margin-top: 60px"
            />
            <div
              style="
                width: 280px;
                margin-left: 60px;
                margin-top: 10px;
                color: #fff;
              "
            >
              <i>* {{ $t("download-app.installation-guide") }}</i>
            </div>
            <a
              id="btn-download-pc"
              href="https://cdn.77577cf.com/download/app/android/77577.apk"
            >
              <img
                src="/images/page/dl-button.png"
                style="width: 260px; margin-left: 40px; margin-top: 60px"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- end::Desktop- Version -->
  </main>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  methods: {
    onSwiper(swiper) {
      console.log(swiper);
    },
    onSlideChange() {
      console.log("slide change");
    },
  },
};
</script>
<style scoped>
@media (max-width: 430px) {
  .main {
    margin-top: -60px !important;
  }
}
</style>
