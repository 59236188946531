<template>
  <br />
  <div class="container-fluid" style="min-height: 400px !important">
    <div class="row m-0 p-0 mt-2">
      <div class="col-md-2  d-md-block"></div>
      <div class="col-12 col-md-8 m-0 p-0">
        <div class=" d-md-block"></div>
        <div class="row p-0 m-0 mb-3">
          <div class="col-12 p-0 mt-2">
            <div class="row p-0 m-0">
              <div class="col-md-12">
                <h2>{{ $t("page-video.tournament-highlights") }}</h2>
              </div>
              <table class="table table-striped">
                <tbody>
                  <tr v-for="(video, index) in videoList" :key="video.id">
                    <td style="width: 30px">{{ index + 1 }}</td>
                    <td>
                      <a href="" v-on:click="videoDetails(video.id)">{{
                        video.title
                      }}</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row mt-3" v-if="videoMeta">
              <div class="col-12">
                <div class="d-flex justify-content-center">
                  <paginate
                    :page-count="videoMeta ? videoMeta.lastPage : 0"
                    :page-range="5"
                    :margin-pages="2"
                    :click-handler="pagination"
                    :prev-text="'‹'"
                    :next-text="'›'"
                    :container-class="'pagination'"
                    :page-class="'page-item'"
                  >
                  </paginate>
                  <br />
                </div>
              </div>
            </div>
            <div class="row mt-3" v-if="loading">
              <div class="col-12">
                <div class="d-flex justify-content-center">
                  <img
                    src="/images/spinner.gif"
                    alt="Image not Found"
                    style="width: 20px"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Paginate from "vuejs-paginate-next";
import router from "../../router";

export default {
  components: {


    Paginate,
  },
  created() {
    console.log(this.$i18n.locale);
    this.getVideosList();
  },
  data() {
    return {
      videoList: null,
      videoMeta: null,
      pageNum: 1,
      loading: false,
    };
  },
  methods: {
    getVideosList() {
      this.loading = true;
      this.$this.$axios
        .get(
          `https://77577.live/api/video-list/${this.$i18n.locale}/${this.pageNum}`
        )
        .then((response) => {
          this.videoList = response.data.list;
          this.videoMeta = response.data.meta;
          this.loading = false;
        })
        .catch(() => {});
    },
    videoDetails(videoId) {
      router.push(`/${this.$i18n.locale}/video/${videoId}`);
    },
    pagination(pageNum) {
      this.pageNum = pageNum;
      this.getVideosList();
    },
  },
};
</script>