<template>
  <div class="container bg-white" style="width: 80% !important">
    <div class="row mb-3 pt-3">
      <div class="col-md-12 p-3 mt-2 text-center">
        <p>Copyright © 2020 - 2023</p>
        <div>
          <a
            href="https://www.dmca.com/Protection/Status.aspx?ID=930ea062-25d9-4297-9dd6-811109f9ea72&amp;refurl=https://en.77577.live/en/live/anchors"
            title="DMCA.com Protection Status"
            class="dmca-badge"
            ><img
              src="https://images.dmca.com/Badges/dmca_protected_sml_120m.png?ID=930ea062-25d9-4297-9dd6-811109f9ea72"
              alt="DMCA.com Protection Status"
          /></a>
        </div>
        <p></p>
      </div>
    </div>
  </div>
</template>