<template>
  <br />
  <div class="container" style="min-height: 400px !important">
    <div class="row mt-3" v-if="loading">
      <div class="col-12">
        <div class="d-flex justify-content-center">
          <img
            src="/images/spinner.gif"
            alt="Image not Found"
            style="width: 20px"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-3">
        <div class="row post-content">
          <div class="col-12 col-md-8">
            <h2>
              {{ postData ? postData.title : "" }}
            </h2>
            <small class="font-italic text-muted" v-if="postData">
              {{
                new Date(postData.create_time).toLocaleString([], {
                  hour12: false,
                  hour: "2-digit",
                  minute: "2-digit",
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })
              }} </small><br />
            <div style="padding: 6px 0px" v-if="postData">
              <span
                v-for="(keywords, index) in postData.keywords
                  .replaceAll('，', ',')
                  .split(',')"
                :key="index"
                style="margin-left: 1px !important"
              >
                <a
                  href=""
                  class="badge badge-dark"
                  v-on:click="tagClicked(keywords)"
                  >{{ keywords }}</a
                >
              </span>
            </div>
            <p v-if="postData">
              <img
                :src="postData.path"
                :alt="postData.path"
                :title="postData.path"
                class="img-fluid"
              />
            </p>
            <p><br /></p>
            <p>
              <strong>{{ postData ? postData.description : "" }}</strong>
            </p>
            <div
              v-if="postData"
              v-html="postData.content"
              style="background-color: rgb(245, 245, 245)"
              class="content"
            ></div>
          </div>
          <div class="col-12 col-md-4 mb-3">
            <div class="card mb-3">
              <div class="card-header bg-primary text-white" v-if="postData">
                {{ $t("page-post.latest") }}
              </div>
              <ul class="list-group list-group-flush">
                <li
                  class="list-group-item"
                  v-for="posts in postsList"
                  :key="posts.id"
                >
                  <small class="text-muted font-italic" v-if="posts">
                    {{
                      new Date(posts.create_time).toLocaleString([], {
                        hour12: false,
                        hour: "2-digit",
                        minute: "2-digit",
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                      })
                    }}</small
                  ><br />
                  <a @click="titleClicked(posts)" href="">
                    {{ posts.title }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import router from "../../router";
export default {
  components: {},
  mounted() {
    console.log("Image not Found");
  },
  created() {
    this.getPostsList();
    this.getLatestPosts();
  },
  data() {
    return {
      postData: null,
      postsList: null,
      pageNum: 1,
      loading: false,
    };
  },
  methods: {
    getPostsList() {
      this.loading = true;
      var currentUrl = window.location.href;
      currentUrl = currentUrl.split("/");
      let postId = currentUrl[currentUrl.length - 1];
      axios
        .get(`https://77577.live/api/post/${this.$i18n.locale}/${postId}`)
        .then((response) => {
          this.postData = response.data;
          this.loading = false;
        })
        .catch(() => {});
    },
    getLatestPosts() {
      axios
        .get(
          `https://77577.live/api/post-list/${this.$i18n.locale}/${this.pageNum}`
        )
        .then((response) => {
          this.postsList = response.data.list;
        })
        .catch(() => {});
    },

    titleClicked(post) {
      router.push(`/${this.$i18n.locale}/post/${post.slug_url}/${post.id}`);
    },
    tagClicked(tag) {
      router.push(`/${this.$i18n.locale}/post/tag/${tag}`);
    },
  },
};
</script>
<style scoped>
</style>