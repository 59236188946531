<template>
  <div class="row">
    <div class="col-12 mt-3">
      <div class="row post-content">
        <div class="col-12 col-md-12">
          <small class="font-italic text-muted"></small><br />
        </div>
        <div class="col-md-2"></div>
        <div class="col-12 col-md-8">
          <h3>{{ videoDetails ? videoDetails.title : "" }}</h3>
          <small class="font-italic text-muted" v-if="videoDetails">
            {{ new Date(videoDetails.create_time).toLocaleString([], {
                hour12: false,
                hour: "2-digit",
                minute: "2-digit",
                year: "numeric",
                month: "numeric",
                day: "numeric",
              })}} </small><br />
          <video ref="videoPlayer" class="video-js layout" preload></video>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {

  },
  data() {
    return {
      videoOptions: {
        autoplay: true,
        controls: true,
        sources: [
          {
            src: "",
            type: "",
          },
        ],
      },
      videoDetails: null,
    };
  },
  mounted() {
    this.player = this.$videojs(this.$refs.videoPlayer, this.videoOptions, () => {});
  },
  created() {
    this.getVideoSource();
  },
  methods: {
    getVideoSource() {
      var currentUrl = window.location.href;
      currentUrl = currentUrl.split("/");
      let videoId = currentUrl[currentUrl.length - 1];
      this.$axios
        .get(`https://77577.live/api/video/${this.$i18n.locale}/${videoId}`)
        .then((response) => {
          this.videoDetails = response.data;
          this.videoOptions.sources[0].src = response.data.path;
          this.videoOptions.sources[0].type = response.data.type;
          this.player.src(this.videoOptions.sources[0].src);
          this.player.type(this.videoOptions.sources[0].type);
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped>
/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .layout {
    height: 250px !important;
    width: 400px !important;
    object-fit: fill;
  }
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width: 321px) {
  /* Styles */
  .layout {
    height: 250px !important;
    width: 400px !important;
    object-fit: fill;
  }
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 320px) {
  /* Styles */

  .layout {
    height: 518px !important;
    width: 921px !important;
    object-fit: fill;
  }
}

/* Tablets, iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .layout {
    height: 578px !important;
    width: 1021px !important;
    object-fit: fill;
  }
}

/* Tablets, iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .layout {
    height: 578px !important;
    width: 1021px !important;
    object-fit: fill;
  }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  .layout {
    height: 578px !important;
    width: 1021px !important;
    object-fit: fill;
  }
}

/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
  .layout {
    height: 578px !important;
    width: 1021px !important;
    object-fit: fill;
  }
}
</style>


