<template>

  <div class="container-fluid p-0" style="min-height: 400px !important;">
    <div class="row p-0 m-0">
      <div class="col-md-12 p-0 m-0" >
        <div >
          <div class="d-flex justify-content-center">
            <div class="d-flex justify-content-center row col-md-8 p-0" style="background-color: white;">
              <div class="col-12 col-md-12">
                <!-- begin::header -->
                <div class="row">
                  <div class="col-12 col-md-7 pr-0">
                    <h4 class="d-inline-block customPhoneH4TextSize">
                      <!-- begin::page title -->
                      <div class="d-inline-block">
                        <br>
                        <a href="" class="link-header"
                          ><img
                            src="/images/icon-basketball.png"
                            width="45"
                            class="p-1 customPhoneH4Icon"
                          />
                          {{
                            $t("basketball.live-score.basketball-live-score")
                          }}
                        </a>
                      </div>
                      <!-- end::page title -->
                      <!-- begin::buttons -->
                      <div class="d-none d-md-inline-block">
                        &nbsp;
                        <a
                          :href="
                            '/' +
                            this.$i18n.locale +
                            '/live/basketball/match-result'
                          "
                          class="btn btn-primary btn-sm"
                          >{{ $t("basketball.match-result.match-result") }}</a
                        >&nbsp;
                        <a
                          :href="
                            '/' +
                            this.$i18n.locale +
                            '/live/basketball/match-schedule'
                          "
                          class="btn btn-primary btn-sm"
                          >{{ $t("basketball.match-result.match-schedule") }}</a
                        >
                      </div>
                      <!-- end::buttons -->
                    </h4>
                  </div>
                  <div class="col-12 col-md-5 pl-0">
                    <div class="row p-0 m-0 mt-1">
                      <!-- begin::hot matches dropdown -->
                      <div
                        class="col-5 col-md-5 text-right"
                        style="border: 0px solid rgb(0, 0, 0)"
                      >
                        <div
                          class="btn-group"
                          style="width: 100%; border: 0px solid rgb(0, 0, 0)"
                        >
                          <span class="badge icon-top-right"
                            ><img src="/images/icon-fire.png" width="20"
                          /></span>
                          <div class="dropdown bootstrap-select mr-2">
                            <select
                              data-style="btn-danger"
                              data-size="8"
                              id="selectHotList"
                              data-toggle="popover"
                              data-placement="top"
                              data-container="body"
                              data-dropup-auto="false"
                              data-display="static"
                              class="selectpicker mr-2"
                            >
                              <option class="bs-title-option" value=""></option>
                              <option value="">
                                {{ $t("basketball.live-score.all-league") }}
                              </option></select
                            ><button
                              type="button"
                              tabindex="-1"
                              class="btn dropdown-toggle bs-placeholder btn-danger"
                              data-display="static"
                              data-toggle="dropdown"
                              role="combobox"
                              aria-owns="bs-select-3"
                              aria-haspopup="listbox"
                              aria-expanded="false"
                              data-id="selectHotList"
                            >
                              <div class="filter-option">
                                <div class="filter-option-inner">
                                  <div class="filter-option-inner-inner">
                                    {{ $t("basketball.live-score.hot-today") }}
                                  </div>
                                </div>
                              </div>
                            </button>
                            <div class="dropdown-menu" style="overflow: hidden">
                              <div
                                class="inner show"
                                role="listbox"
                                id="bs-select-3"
                                tabindex="-1"
                                style="overflow-y: auto"
                              >
                                <ul
                                  class="dropdown-menu inner show"
                                  role="presentation"
                                  style="margin-top: 0px; margin-bottom: 0px"
                                >
                                  <li v-on:click="resetHotLeagues()">
                                    <a
                                      role="option"
                                      class="dropdown-item"
                                      id="bs-select-3-0"
                                      tabindex="0"
                                      >{{
                                        $t("football.live-score.all-league")
                                      }}</a
                                    >
                                  </li>
                                  <template
                                    v-for="hotLeagues in hotLeagues"
                                    :key="hotLeagues.id"
                                  >
                                    <li
                                      v-on:click="getHotLeagues(hotLeagues.id)"
                                    >
                                      <a
                                        role="option"
                                        class="dropdown-item"
                                        id="bs-select-3-1"
                                        tabindex="0"
                                      >
                                        {{
                                          this.$i18n.locale == "cn"
                                            ? hotLeagues.nameCn
                                            : hotLeagues.nameEn
                                        }}
                                      </a>
                                    </li>
                                  </template>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- end:: hot matches dropdown-->
                      <!-- begin::Search Features -->
                      <div class="col-7 col-md-7 p-0 m-0 pl-1 text-right">
                        <div class="input-group mb-2 p-0">
                          <input
                            type="text"
                            id="inputLeagueTeamName"
                            v-model="inputLeagueTeamName"
                            :placeholder="
                              $t('football.live-score.league') +
                              ' / ' +
                              $t('football.live-score.team')
                            "
                            data-toggle="popover"
                            data-placement="top"
                            data-container="body"
                            class="form-control p-1 pl-1 customPhoneTextSize"
                            style="font-size: 80%"
                            data-original-title=""
                          />
                          <div class="input-group-append">
                            <button
                              v-on:click="searchTeamLeagueName()"
                              class="input-group-text bg-danger text-white btn p-1 customPhoneTextSize"
                            >
                              {{ $t("basketball.live-score.search") }}
                            </button>
                          </div>
                        </div>
                      </div>
                      <!-- end::Search Features -->
                    </div>
                  </div>
                </div>
                <!-- end::header title -->

                <!-- begin::loader section for mobile version -->
                <div
                  class="col-md-12 loading-mobile-version"
                  v-if="loading"
                  style="
                    margin-top: 20px !important;
                    margin-left: -30px !important;
                  "
                >
                  <div class="row">
                    <div
                      class="col-12 col-md-7 pr-0"
                      style="margin-left: 40px; margin-top: -20px"
                    >
                      <p style="font-size: 13px !important">
                        {{ $t("basketball.live-score.information-is-loading") }}
                        <img
                          src="/images/spinner.gif"
                          alt="spinner"
                          style="width: 8px"
                        />
                      </p>
                    </div>
                  </div>
                </div>
                <!-- end::loader section for mobile version -->
                <!-- begin::mobile version -->

                <!-- begin::tabs -->
                <ul class="nav nav-tabs">
                  <li class="nav-item">
                    <a
                      style="cursor: pointer"
                      class="nav-link"
                      :class="{ active: currentState == 'live' }"
                      v-on:click="getMatchList(1, 'live')"
                      >{{ $t("football.live-score.live") }}</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      style="cursor: pointer"
                      class="nav-link"
                      :class="{ active: currentState == 'upcoming' }"
                      v-on:click="getMatchList(1, 'upcoming')"
                      >{{ $t("state.upcoming") }}</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      style="cursor: pointer"
                      class="nav-link"
                      :class="{ active: currentState == 'finished' }"
                      v-on:click="getMatchList(1, 'finished')"
                      >{{ $t("state.finished") }}</a
                    >
                  </li>

                  <li class="nav-item">
                    <a
                      style="cursor: pointer"
                      class="nav-link"
                      :class="{ active: currentState == 'hotMatches' }"
                      v-on:click="getHotMatches('hotMatches')"
                      >{{ $t("football.live-score.hot-matches") }}</a
                    >
                  </li>
                </ul>
                <!-- end::tabs -->
                <div class="table-zqbf row d-block d-md-none" v-if="matchList">
                  <center>
                    <div class="m-0 p-1 bg-dark text-white" style="width: 94%">
                      <div class="col-12 text-left" style="font-size: 16px">
                        {{ currentDate() }}
                      </div>
                    </div>
                  </center>
                  <div
                    class="col-sm-12"
                    v-for="match in matchList"
                    :key="match.id"
                  >
                    <div
                      class="row"
                      v-if="
                        match.league_Info &&
                        match.league_Info.cn_name &&
                        match.home_Info.en_name &&
                        match.away_Info.en_name
                      "
                    >
                      <div class="col-sm-12">
                        <div
                          class="row m-0 p-1"
                          :style="`background-color:${
                            match.league_Info && match.league_Info.primary_color
                              ? match.league_Info.primary_color
                              : 'black'
                          };`"
                        >
                          <div class="col-12 text-left text-white">
                            {{ changeTimeZone(match.match_timing) }}

                            <div class="btn p-1 text-white">
                              <span>
                                {{
                                  match.league && this.$i18n.locale == "cn"
                                    ? match.league_Info.cn_name
                                    : match.league_Info.short_name
                                }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div
                            class="col-1"
                            style="margin-top: 35px;position: relative;left: 10px;"
                          >
                            <b style="font-size: 0.7rem; color: red">
                              {{ currentQuarter(match.status_id) }}</b
                            >
                          </div>
                          <div class="col-11">
                            <table>
                              <tr>
                                <td style="width: 50px">
                                  <img
                                    :src="
                                      match.home_Info.logo
                                        ? match.home_Info.logo
                                        : '/images/default-basketball.jpg'
                                    "
                                    width="30"
                                    height="30"
                                    style="margin-top: 4px !important"
                                  />
                                </td>
                                <td style="width: 100px">
                                  {{
                                    match.league_Info &&
                                    this.$i18n.locale == "cn"
                                      ? match.home_Info.cn_name
                                      : match.home_Info.en_name || "Team Name"
                                  }}
                                </td>
                                <td style="width: 50px">
                                  {{ match.home_Info.section_1 || "-" }}
                                </td>
                                <td style="width: 50px">
                                  {{ match.home_Info.section_2 || "-" }}
                                </td>
                                <td style="width: 50px">
                                  {{ match.home_Info.section_3 || "-" }}
                                </td>
                                <td style="width: 50px">
                                  {{ match.home_Info.section_4 || "-" }}
                                </td>
                              </tr>
                              <br />
                              <tr>
                                <td style="width: 50px">
                                  <img
                                    :src="
                                      match.away_Info.logo
                                        ? match.away_Info.logo
                                        : '/images/default-basketball.jpg'
                                    "
                                    width="30"
                                    height="30"
                                  />
                                </td>
                                <td style="width: 200px">
                                  {{
                                    match.league_Info &&
                                    this.$i18n.locale == "cn"
                                      ? match.away_Info.cn_name
                                      : match.away_Info.en_name
                                  }}
                                </td>
                                <td style="width: 50px">
                                  {{ match.away_Info.section_1 || "-" }}
                                </td>
                                <td style="width: 50px">
                                  {{ match.away_Info.section_2 || "-" }}
                                </td>
                                <td style="width: 50px">
                                  {{ match.away_Info.section_3 || "-" }}
                                </td>
                                <td style="width: 50px">
                                  {{ match.away_Info.section_4 || "-" }}
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>

                        <div
                          class="row m-0 p-0"
                          style="background: rgb(251, 251, 251)"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end::mobile version -->

                <!-- begin::desktop version -->
                <div class="d-none d-md-block main-content">
                  <template v-if="loading || moreMatches">
                    <p style="position: absolute; top: -70px; left: 80px">
                      {{
                        moreMatches
                          ? $t("football.live-score.loading-more-matches")
                          : $t("basketball.live-score.information-is-loading")
                      }}
                      <img
                        src="/images/spinner.gif"
                        alt="spinner"
                        style="width: 10px"
                      />
                    </p>
                  </template>
                  <table
                    class="table table-sm table-striped table-bordered table-hover text-center"
                    style="font-size: 12px"
                  >
                    <template v-for="(match, index) in matchList" :key="index">
                      <tbody
                        class="mb-2"
                        v-if="
                          match.league_Info &&
                          match.league_Info.cn_name &&
                          match.home_Info.en_name &&
                          match.away_Info.en_name
                        "
                      >
                        <tr>
                          <!-- begin::display League -->
                          <th
                            colspan="2"
                            class="text-left pl-4 text-white"
                            :style="`background-color:${
                              match.league_Info &&
                              match.league_Info.primary_color
                                ? match.league_Info.primary_color
                                : 'black'
                            };`"
                          >
                            <span>
                              {{
                                match.league_Info && this.$i18n.locale == "cn"
                                  ? match.league_Info.cn_name
                                  : match.league_Info.short_name
                              }}
                            </span>
                            :
                            {{ currentQuarter(match.status_id) }}
                          </th>
                          <!-- end::display league -->
                          <th
                            bgcolor="#7EABD3"
                            title="First Quarter"
                            class="text-white"
                          >
                            {{ $t("state.first-quarter-s") }}
                          </th>
                          <th bgcolor="#7EABD3" class="text-white">
                            {{ $t("state.second-quarter-s") }}
                          </th>
                          <th bgcolor="#7EABD3" class="text-white">
                            {{ $t("state.third-quarter-s") }}
                          </th>
                          <th bgcolor="#7EABD3" class="text-white">
                            {{ $t("state.fourth-quarter-s") }}
                          </th>
                          <th bgcolor="#7EABD3" class="text-white">
                            {{ $t("state.half-s") }}
                          </th>
                          <th bgcolor="#7EABD3" title="Full" class="text-white">
                            {{ $t("state.Full-s") }}
                          </th>
                          <th
                            bgcolor="#7EABD3"
                            class="text-white"
                            style="width: 50px"
                          >
                            {{ $t("state.difference-s") }}
                          </th>
                          <th
                            bgcolor="#7EABD3"
                            class="text-white"
                            style="width: 50px"
                          >
                            {{ $t("state.points-s") }}
                          </th>
                          <th
                            bgcolor="#7EABD3"
                            class="text-white"
                            style="width: 50px"
                          >
                            {{ $t("state.ml-s") }}
                          </th>
                          <th
                            bgcolor="#7EABD3"
                            class="text-white"
                            style="width: 80px"
                          >
                            {{ $t("state.handi") }}
                          </th>
                          <th
                            bgcolor="#7EABD3"
                            title="Total"
                            class="text-white"
                            style="width: 120px"
                          >
                            {{ $t("state.over-under-s") }}
                          </th>
                          <th
                            bgcolor="#7EABD3"
                            class="text-white text-left"
                            style="width: 200px !important"
                          >
                            {{ $t("state.game-data-analysis") }}
                          </th>
                        </tr>
                        <tr>
                          <td
                            rowspan="2"
                            class="align-middle bg-white"
                            style="font-size: 12px"
                          >
                            {{
                              new Date(match.match_timing).toLocaleDateString(
                                [],
                                {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                }
                              )
                            }}
                            <br />
                            {{ changeTimeZone(match.match_timing) }}
                          </td>
                          <td
                            class="text-left"
                            style="width: 260px; cursor: pointer"
                            v-on:click="
                              getTeamDetails(
                                match.id,
                                match.home_team_id,
                                'home'
                              )
                            "
                            data-toggle="modal"
                            data-target="#teamInformationPopup"
                          >
                            <button class="btn p-0">
                              <img
                                :src="
                                  match.home_Info.logo
                                    ? match.home_Info.logo
                                    : '/images/default-basketball.jpg'
                                "
                                width="20"
                                height="20"
                              />
                            </button>
                            <span class="p-0" style="display: inline-block">
                              {{
                                this.$i18n.locale == "cn"
                                  ? match.home_Info.cn_name
                                  : match.home_Info.en_name || "Team Name"
                              }}
                            </span>
                          </td>
                          <td class="align-middle">
                            <span>
                              {{
                                match.home_Info.section_1 != 0
                                  ? match.home_Info.section_1
                                  : ""
                              }}
                            </span>
                          </td>
                          <td class="align-middle">
                            {{
                              match.home_Info.section_2 != 0
                                ? match.home_Info.section_2
                                : ""
                            }}
                          </td>
                          <td class="align-middle">
                            {{
                              match.home_Info.section_3 != 0
                                ? match.home_Info.section_3
                                : ""
                            }}
                          </td>
                          <td class="align-middle">
                            {{
                              match.home_Info.section_4 != 0
                                ? match.home_Info.section_4
                                : ""
                            }}
                          </td>
                          <td
                            class="align-middle"
                            style="width: 60px !important"
                          >
                            <span style="color: blue">
                              {{
                                match.home_Info.section_1 +
                                  match.home_Info.section_2 !=
                                0
                                  ? match.home_Info.section_1 +
                                    match.home_Info.section_2
                                  : ""
                              }}
                              /
                              {{
                                match.home_Info.section_3 +
                                  match.home_Info.section_4 !=
                                0
                                  ? match.home_Info.section_3 +
                                    match.home_Info.section_4
                                  : ""
                              }}
                            </span>
                          </td>
                          <td class="align-middle">
                            <b :style="`color:${scoreColor(match.status_id)};`">
                              {{
                                match.home_Info.section_1 +
                                  match.home_Info.section_2 +
                                  match.home_Info.section_3 +
                                  match.home_Info.section_4 !=
                                0
                                  ? match.home_Info.section_1 +
                                    match.home_Info.section_2 +
                                    match.home_Info.section_3 +
                                    match.home_Info.section_4
                                  : ""
                              }}
                            </b>
                          </td>
                          <td class="align-middle">
                            {{ $t("state.half-s") }}:
                            <span v-if="match.status_id != 1">
                              {{
                                match.home_Info.section_1 +
                                match.home_Info.section_2 -
                                match.away_Info.section_1 +
                                match.away_Info.section_2
                              }}
                            </span>
                          </td>
                          <td class="align-middle">
                            {{ $t("state.half-s") }}:
                            <span v-if="match.status_id != 1">
                              {{
                                match.home_Info.section_1 +
                                match.home_Info.section_2 +
                                (match.away_Info.section_1 +
                                  match.away_Info.section_2)
                              }}
                            </span>
                          </td>
                          <!-- begin::euro column -->
                          <td style="cursor: pointer; width: 100px !important">
                            <!-- begin::euro column -->
                            <span
                              v-if="
                                match.odds &&
                                match.odds.live &&
                                match.odds.live.euro
                              "
                            >
                              {{ match.odds.live.euro.home }}
                            </span>
                            <span
                              v-else-if="
                                match.odds &&
                                match.odds.init &&
                                match.odds.init.euro
                              "
                            >
                              {{ match.odds.init.euro.home }}
                            </span>
                            <span v-else> - </span>
                            &nbsp;&nbsp;
                            <span
                              v-if="
                                match.odds &&
                                match.odds.live &&
                                match.odds.live.euro
                              "
                            >
                              {{ match.odds.live.euro.handicap }}
                            </span>
                            <span
                              v-else-if="
                                match.odds &&
                                match.odds.init &&
                                match.odds.init.euro
                              "
                            >
                              {{ match.odds.init.euro.handicap }}
                            </span>
                            <span v-else> - </span>
                          </td>
                          <!-- end::euro column  -->
                          <!-- begin::asia column -->
                          <td style="cursor: pointer; width: 100px !important">
                            <!-- begin::euro column -->
                            <span
                              v-if="
                                match.odds &&
                                match.odds.live &&
                                match.odds.live.asia
                              "
                            >
                              {{ match.odds.live.asia.home }}
                            </span>
                            <span
                              v-else-if="
                                match.odds &&
                                match.odds.init &&
                                match.odds.init.asia
                              "
                            >
                              {{ match.odds.init.asia.home }}
                            </span>
                            <span v-else> - </span>
                            &nbsp;&nbsp;
                            <span
                              v-if="
                                match.odds &&
                                match.odds.live &&
                                match.odds.live.asia
                              "
                            >
                              {{ match.odds.live.asia.handicap }}
                            </span>
                            <span
                              v-else-if="
                                match.odds &&
                                match.odds.init &&
                                match.odds.init.asia
                              "
                            >
                              {{ match.odds.init.asia.handicap }}
                            </span>
                            <span v-else> - </span>
                          </td>
                          <!-- end::asia column  -->
                          <!-- begin::bigSmall column -->
                          <td style="cursor: pointer; width: 100px !important">
                            <!-- begin::euro column -->
                            <span
                              v-if="
                                match.odds &&
                                match.odds.live &&
                                match.odds.live.bigSmall
                              "
                            >
                              {{ match.odds.live.bigSmall.home }}
                            </span>
                            <span
                              v-else-if="
                                match.odds &&
                                match.odds.init &&
                                match.odds.init.bigSmall
                              "
                            >
                              {{ match.odds.init.bigSmall.home }}
                            </span>
                            <span v-else> - </span>
                            &nbsp;&nbsp;
                            <span
                              v-if="
                                match.odds &&
                                match.odds.live &&
                                match.odds.live.bigSmall
                              "
                            >
                              {{ match.odds.live.bigSmall.handicap }}
                            </span>
                            <span
                              v-else-if="
                                match.odds &&
                                match.odds.init &&
                                match.odds.init.bigSmall
                              "
                            >
                              {{ match.odds.init.bigSmall.handicap }}
                            </span>
                            <span v-else> - </span>
                          </td>

                          <td
                            rowspan="2"
                            style="
                              background-color: white;
                              text-align: center;
                              vertical-align: middle;
                            "
                          >
                            <button
                              class="btn btn-warning btn-sm"
                              data-toggle="modal"
                              data-target="#exampleModal"
                              v-if="match.odds"
                              v-on:click="getMatchOdds(match.id, match)"
                            >
                              {{ $t("odds.index") }}
                            </button>

                            &nbsp;

                            <button
                              class="btn btn-primary btn-sm"
                              data-toggle="modal"
                              data-target="#leaguePopup"
                              v-on:click="
                                getLeagueDetails(match.competition_id)
                              "
                            >
                              {{ $t("basketball.league-details.league") }}
                            </button>
                            &nbsp;
                            <br />
                            <br />
                            <button
                              class="btn btn-warning btn-sm"
                              data-toggle="modal"
                              data-target="#matchStatics"
                              v-on:click="getMatchStatics(match.id)"
                            >
                              {{ $t("football.h2h.h2h") }}
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td
                            class="text-left"
                            style="width: 260px; cursor: pointer"
                            v-on:click="
                              getTeamDetails(
                                match.id,
                                match.away_team_id,
                                'away'
                              )
                            "
                          >
                            <button class="btn p-0">
                              <img
                                data-toggle="modal"
                                data-target="#teamInformationPopup"
                                :src="
                                  match.away_Info.logo
                                    ? match.away_Info.logo
                                    : '/images/default-basketball.jpg'
                                "
                                width="20"
                                height="20"
                              />
                            </button>
                            <span class="p-0" style="display: inline-block">
                              {{
                                this.$i18n.locale == "cn"
                                  ? match.away_Info.cn_name
                                  : match.away_Info.en_name
                              }}
                            </span>
                          </td>
                          <td class="align-middle">
                            <span>
                              {{
                                match.away_Info.section_1 != 0
                                  ? match.away_Info.section_1
                                  : ""
                              }}
                            </span>
                          </td>
                          <td class="align-middle">
                            <span>
                              {{
                                match.away_Info.section_2 != 0
                                  ? match.away_Info.section_2
                                  : ""
                              }}
                            </span>
                          </td>
                          <td class="align-middle">
                            <span>
                              {{
                                match.away_Info.section_3 != 0
                                  ? match.away_Info.section_3
                                  : ""
                              }}
                            </span>
                          </td>
                          <td class="align-middle">
                            <span>
                              {{
                                match.away_Info.section_4 != 0
                                  ? match.away_Info.section_4
                                  : ""
                              }}
                            </span>
                          </td>

                          <td>
                            <span style="color: blue">
                              {{
                                match.away_Info.section_1 +
                                  match.away_Info.section_2 !=
                                0
                                  ? match.away_Info.section_1 +
                                    match.away_Info.section_2
                                  : ""
                              }}
                              /
                              {{
                                match.away_Info.section_3 +
                                  match.away_Info.section_4 !=
                                0
                                  ? match.away_Info.section_3 +
                                    match.away_Info.section_4
                                  : ""
                              }}
                            </span>
                          </td>
                          <td class="align-middle">
                            <b :style="`color:${scoreColor(match.status_id)};`">
                              <span v-if="match.status_id != 1">
                                {{
                                  match.away_Info.section_1 +
                                  match.away_Info.section_2 +
                                  match.away_Info.section_3 +
                                  match.away_Info.section_4
                                }}
                              </span>
                            </b>
                          </td>

                          <td class="align-middle">
                            {{ $t("basketball.state.f") }}:
                            <span v-if="match.status_id != 1">
                              {{
                                match.home_Info.section_1 +
                                match.home_Info.section_2 +
                                match.home_Info.section_3 +
                                match.home_Info.section_4 -
                                (match.away_Info.section_1 +
                                  match.away_Info.section_2 +
                                  match.away_Info.section_3 +
                                  match.away_Info.section_4)
                              }}
                            </span>
                          </td>

                          <td
                            id="popover-target-492775-3"
                            style="cursor: pointer"
                          >
                            {{ $t("basketball.state.f") }}:
                            <span
                              style="float: right"
                              v-if="match.status_id != 1"
                            >
                              {{
                                match.home_Info.section_1 +
                                match.home_Info.section_2 +
                                match.home_Info.section_3 +
                                match.home_Info.section_4 +
                                (match.away_Info.section_1 +
                                  match.away_Info.section_2 +
                                  match.away_Info.section_3 +
                                  match.away_Info.section_4)
                              }}
                            </span>
                          </td>

                          <!-- begin::euro column -->
                          <td style="cursor: pointer; width: 100px !important">
                            <!-- begin::euro column -->
                            <span
                              v-if="
                                match.odds &&
                                match.odds.live &&
                                match.odds.live.euro
                              "
                            >
                              {{ match.odds.live.euro.away }}
                            </span>
                            <span
                              v-else-if="
                                match.odds &&
                                match.odds.init &&
                                match.odds.init.euro
                              "
                            >
                              {{ match.odds.init.euro.away }}
                            </span>
                            <span v-else> - </span>
                          </td>
                          <!-- end::euro column  -->
                          <!-- begin::asia column -->
                          <td style="cursor: pointer; width: 100px !important">
                            <!-- begin::euro column -->
                            <span
                              v-if="
                                match.odds &&
                                match.odds.live &&
                                match.odds.live.asia
                              "
                            >
                              {{ match.odds.live.asia.away }}
                            </span>
                            <span
                              v-else-if="
                                match.odds &&
                                match.odds.init &&
                                match.odds.init.asia
                              "
                            >
                              {{ match.odds.init.asia.away }}
                            </span>
                            <span v-else> - </span>
                          </td>
                          <!-- end::asia column  -->
                          <!-- begin::bigSmall column -->
                          <td style="cursor: pointer; width: 100px !important">
                            <!-- begin::euro column -->
                            <span
                              v-if="
                                match.odds &&
                                match.odds.live &&
                                match.odds.live.bigSmall
                              "
                            >
                              {{ match.odds.live.bigSmall.away }}
                            </span>
                            <span
                              v-else-if="
                                match.odds &&
                                match.odds.init &&
                                match.odds.init.bigSmall
                              "
                            >
                              {{ match.odds.init.bigSmall.away }}
                            </span>
                            <span v-else> - </span>
                          </td>
                          <!-- end::bigSmall column  -->
                        </tr>
                        <tr>
                          <td colspan="14" class="p-3 bg-white"></td>
                        </tr>
                      </tbody>
                    </template>

                    <tbody
                      v-if="matchList == '' || moreMatches"
                      style="background-color: white"
                    >
                      <tr>
                        <td colspan="14">
                          <div
                            class="text-white p-2"
                            style="background-color: rgb(52, 144, 220)"
                          >
                            {{
                              matchList == ""
                                ? $t(
                                    "basketball.live-score.temporarily-no-match"
                                  )
                                : moreMatches
                                ? $t("football.live-score.loading-more-matches")
                                : ""
                            }}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- end::desktop version -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FOOTER />
  <!-- begin::league Information popup -->
  <LeagueDetailsModal :leagueId="leagueId" :type="'basketball'" />
  <!-- end::league information popup -->

  <!-- begin::team Information popup -->
  <TeamDetailsModal
    :findTeam="findTeam"
    :teamMatchId="teamMatchId"
    :teamId="teamId"
    :type="'basketball'"
  />
  <!-- end::team Information popup  -->

  <MatchOddsModal
    :matchId="matchId"
    :matchDetails="matchDetails"
    :type="'basketball'"
  />

  <!-- begin::modal for Animation -->
  <MatchAnimateModal
    :matchAnimationId="matchAnimationId"
    :scoreDetails="scoreDetails"
    v-if="scoreDetails"
  />
  <!-- end::modal for Animation -->
  <MatchStatistics :matchStaticId="matchStaticId" :type="'basketball'" />
</template>
<script>

import TeamDetailsModal from "../modals/modal-team-details.vue";
import MatchAnimateModal from "./../modals/modal-match-animation-modal.vue";
import LeagueDetailsModal from "./../modals/league-details-basketball-modal.vue";
import MatchOddsModal from "./../modals/match-odds-modal.vue";
import MatchStatistics from "./../modals/match-statistics-basketball.vue";
// import Popper from "vue3-popper";

export default {
  components: {

    TeamDetailsModal,
    MatchAnimateModal,
    LeagueDetailsModal,
    MatchOddsModal,
    // Popper,
    MatchStatistics,
  },

  data() {
    return {
      matchList: null,
      hotLeagues: [],
      scoreDetails: null,
      loading: false,
      pagesCount: null,
      currentPage: 1,
      language: "english",
      inputLeagueTeamName: "",
      dayNumer: 1,
      teamId: null,
      leagueId: null,
      matchAnimationId: null,
      hotMatches: false,
      moreMatches: false,
      returnResponse: true,
      matchStatus: "live",
      matchId: null,
      matchDetails: null,
      teamMatchId: null,
      findTeam: null,
      matchStaticId: null,
      currentState: "live",
    };
  },
  mounted() {
    window.$('[data-toggle="tooltip"]').tooltip();
  },
  created() {
    this.getMatchList(this.currentPage, this.currentState);
    // on scroll call display more matches
    window.addEventListener("scroll", () => {
      if (this.hotMatches) {
        this.loading = false;
        return "";
      }

      this.getMatchList(this.currentPage, this.currentState);
    });
    // refresh frontend every 30 second
    setInterval(() => {
      this.loading = true;
      if (this.hotMatches) {
        this.loading = false;
        return "";
      }

      this.currentPage > 1 ? (this.loading = false) : (this.loading = true);
      this.$axios
        .get(
          `${this.BASE_SERVER_URI}/api6/basketball/matchlist/today?matchStatus=${this.currentState}&pageNumber=${this.currentPage}`
        )
        .then((response) => {
          this.matchList = response.data.matchList;
          this.pagesCount = response.data.totalPages;
          this.hotLeagues = response.data.hotLeagues;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    }, 30000);
  },

  methods: {
    // method to get the current date
    currentDate() {
      const moment = require("moment-timezone");

      let timezone,
        todayDatetime = null;
      if (this.$i18n.locale == "en") {
        timezone = "America/New_York";
      } else if (this.$i18n.locale == "ty") {
        timezone = "Asia/Jakarta";
      } else if (this.$i18n.locale == "vi") {
        timezone = "Asia/Vientiane";
      } else if (this.$i18n.locale == "th") {
        timezone = "Asia/Bangkok";
      } else {
        timezone = "Asia/Shanghai";
      } //

      todayDatetime = moment(new Date(), "YYYY/MM/DD hh:mm:ss").tz(
        `${timezone}`
      );
      return moment(todayDatetime, "YYYY/MM/DD hh:mm:ss").format("YYYY/MM/DD");
    },
    // method to get score details
    getScoreDetails(match) {
      this.scoreDetails = match;
    },
    // method to get hot leagues
    getHotLeagues(id) {
      this.hotMatches = true;
      this.$axios
        .get(
          `${this.BASE_SERVER_URI}/api6/basketball/matchlist/today?hotLeagueId=${id}`
        )
        .then((response) => {
          this.matchList = response.data.matchList;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getHotMatches(state) {
      this.hotMatches = true;
      this.currentState = state;
      this.$axios
        .get(
          `${this.BASE_SERVER_URI}/api6/basketball/matchlist/today?hotMatches=true`
        )
        .then((response) => {
          this.matchList = response.data.hotMatches;
        })
        .catch((error) => {
          console.log(error);
        }); // code to run on error
    },
    resetHotLeagues() {
      this.hotMatches = false;
      this.getMatchList(1, this.matchStatus);
    },

    getMatchList(pageNumber, state) {
      if (this.loading) return;

      this.loading = true;

      if (this.returnResponse) {
        this.returnResponse = false;
        this.$axios
          .get(
            `${this.BASE_SERVER_URI}/api6/basketball/matchlist/today?matchStatus=${state}&pageNumber=${pageNumber}`
          )
          .then((response) => {
            this.currentState = state;
            this.matchList = response.data.matchList;
            this.pagesCount = response.data.totalPages;
            this.hotLeagues = response.data.hotLeagues;
            this.returnResponse = true;
            this.moreMatches = false;
            this.loading = false;
            this.currentPage = this.currentPage + 1;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    // method to get team details
    getTeamDetails($matchId, teamId, team) {
      this.teamId = teamId;
      this.teamMatchId = $matchId;
      this.findTeam = team;
    },
    getMatchOdds(id, match) {
      this.matchId = id;
      this.matchDetails = match;
    },
    // method to get the league details
    getLeagueDetails(id) {
      this.leagueId = id;
    },
    // method to get the current country list.
    getMatchStatics(matchId) {
      this.matchStaticId = matchId;
    },

    changeLanguage(language) {
      this.language = language;
    },

    searchTeamLeagueName() {
      this.hotMatches = true;
      // let liveaway = this.dayNumer;
      let inputkeyword = this.inputLeagueTeamName;
      this.loading = true;
      this.$axios
        .get(
          `${this.BASE_SERVER_URI}/api6/basketball/matchlist/today?pageNumber=1&locale=${this.$i18n.locale}&inputLeagueTeamName=${inputkeyword}`
        )
        .then((response) => {
          this.pagesCount = response.data.totalPages;
          this.matchList = response.data.matchList;

          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },

    scoreColor(status_code) {
      if (status_code == 10) return "red";
      else if (
        status_code == 2 ||
        status_code == 3 ||
        status_code == 4 ||
        status_code == 5 ||
        status_code == 6 ||
        status_code == 7 ||
        status_code == 8
      )
        return "blue";
      else return "black";
    },

    changeTimeZone(matchTime) {
      matchTime += "+08:00";
      if (this.$i18n.locale == "en") {
        let timeZoneUSA = new Date(matchTime)
          .toLocaleTimeString("en-US", {
            timeZone: "America/New_York",
            hour12: false,
            hour: "2-digit",
            minute: "2-digit",
          })
          .slice(0, 5);
        return timeZoneUSA;
      } else if (this.$i18n.locale == "ty") {
        let timeZoneid = new Date(matchTime)
          .toLocaleTimeString("en-US", {
            timeZone: "Asia/Jakarta",
            hour12: false,
            hour: "2-digit",
            minute: "2-digit",
          })
          .slice(0, 5);
        return timeZoneid;
      } else if (this.$i18n.locale == "vi") {
        let timeZonevi = new Date(matchTime)
          .toLocaleTimeString("en-US", {
            timeZone: "Asia/Vientiane",
            hour12: false,
            hour: "2-digit",
            minute: "2-digit",
          })
          .slice(0, 5);
        return timeZonevi;
      } else if (this.$i18n.locale == "th") {
        var timeZoneth = new Date(matchTime)
          .toLocaleTimeString("en-US", {
            timeZone: "Asia/Bangkok",
            hour12: false,
            hour: "2-digit",
            minute: "2-digit",
          })
          .slice(0, 5);
        return timeZoneth;
      } else
        return new Date(matchTime)
          .toLocaleString([], {
            hour12: false,
            hour: "2-digit",
            minute: "2-digit",
          })
          .slice(0, 5);
    },
    currentQuarter(status_code) {
      switch (status_code) {
        case 1:
          return this.$t("state.upcoming-s");

        case 2:
          return this.$t("state.second-quarter-s");
        case 3:
          return this.$t("state.second-quarter-s");
        case 4:
          return this.$t("state.second-quarter-s");
        case 5:
          return this.$t("state.second-quarter-s");
        case 6:
          return this.$t("state.third-quarter-s");
        case 7:
          return this.$t("state.third-quarter-s");
        case 8:
          return this.$t("state.fourth-quarter-s");

        case 12:
          return this.$t("state.cancelled-s");

        case 15:
          return this.$t("state.to-be-determined-s");
        case 10:
          return this.$t("state.finished-s");
        default:
          return "";
      }
    },
  },
};
</script>
<style scoped>
/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .loading-desktop-version {
    display: none;
  }

  .loading-mobile-version {
    display: block;
  }
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width: 321px) {
  /* Styles */

  .loading-desktop-version {
    display: none;
  }

  .loading-mobile-version {
    display: block;
  }
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 320px) {
  /* Styles */

  .loading-desktop-version {
    display: none;
  }

  .loading-mobile-version {
    display: block;
  }
}

/* Tablets, iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .loading-desktop-version {
    display: block;
  }

  .loading-mobile-version {
    display: none;
  }
}

/* Tablets, iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .loading-desktop-version {
    display: block;
  }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  .loading-desktop-version {
    display: block;
  }

  .loading-mobile-version {
    display: none;
  }
}

/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
  .loading-desktop-version {
    display: block;
  }

  .loading-mobile-version {
    display: none;
  }
}

@keyframes blink {
  100% {
    color: transparent;
  }
}

.loader__dot {
  animation: 1s blink infinite;
}

.light {
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-text-color: #333333;
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: #eeeeee;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 0px;
}

.player-detail-list {
  padding: 0;
  margin: 0;
  border: solid 0px #000;
}

.player-detail-list label {
  padding: 0;
  margin: 0;
  padding: 2px 4px 2px 4px;
}

.player-detail-list div {
  padding: 0;
  margin: 0;
  padding: 2px 4px 2px 4px;
}

.divider {
  height: 30px;
  background-color: #f7be57;
  color: white;
}

.league-title {
  color: white;
}

.odds-table-company-title {
  background-color: white;
}

.odds-table-match-title {
  background-color: lightyellow;
}

.odds-table-live-title {
  background-color: aliceblue;
}

.odds-table-title {
  background-color: lightgrey;
}

.odds-type {
  background-color: cornflowerblue;
  color: white;
  font-weight: bold;
}

.league-title {
  color: white;
}

.odds-table-company-title {
  background-color: white;
}

.odds-table-match-title {
  background-color: lightyellow;
}

.popover {
  width: 750px !important;
  max-width: 1000px !important;
}
</style>



