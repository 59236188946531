<template>
  <div
    class="container p-0"
    style="min-height: 400px !important; background-color: white; width: 70%"
  >
    <div class="row p-0 m-0">
      <div class="col-md-12 p-0 m-0">
        <div class="wrap fix-frame">
          <div
            class="b-overlay-wrap position-relative"
            style="margin-top: 20px"
          >
            <!-- begin::standings buttons -->
            <div class="text-center pb-2 pt-2">
              <button
                lg="2"
                type="button"
                :class="
                  football
                    ? 'btn mr-2 btn-primary btn-lg rounded-pill'
                    : 'mr-2 btn btn-outline-secondary btn-lg rounded-pill'
                "
                v-on:click="activefoobtallData()"
              >
                {{ $t("common.menu.football") }}
              </button>
              <button
                v-on:click="activeBasketballData()"
                lg="2"
                type="button"
                :class="
                  basketball
                    ? 'btn mr-2 btn-primary btn-lg rounded-pill'
                    : 'mr-2 btn btn-outline-secondary btn-lg rounded-pill'
                "
              >
                {{ $t("common.menu.basketball") }}
              </button>
            </div>
            <!-- end::standings buttons -->

            <!-- begin::football Section -->
            <div
              class="card"
              v-if="football && !loading"
              style="width: 85%; margin-left: 20px; margin-top: 20px"
            >
              <div class="tabs row no-gutters">
                <div class="col-auto" style="background-color: rgb(52, 58, 64)">
                  <ul
                    role="tablist"
                    class="nav nav-pills flex-column small card-header h-100 border-bottom-0 rounded-0"
                  >
                    <span>
                      <li
                        role="presentation"
                        class="nav-item"
                        v-for="league in footballLeagueList"
                        :key="league.id"
                      >
                        <a
                          style="color: white"
                          role="tab"
                          aria-selected="true"
                          aria-setsize="15"
                          aria-posinset="1"
                          href="#"
                          target="_self"
                          class="nav-link"
                          :class="footballActive == league.id ? 'active' : ''"
                          v-on:click="getFootballStandings(league.id)"
                        >
                          {{
                            this.$i18n.locale == "cn"
                              ? league.nameCn
                              : league.nameEn
                          }}</a
                        >
                      </li>
                    </span>
                  </ul>
                </div>
                <div class="tab-content col">
                  <div
                    role="tabpanel"
                    aria-hidden="false"
                    class="tab-pane active card-body"
                  >
                    <span>
                      <template
                        v-for="football in footballLeagueList"
                        :key="football.id"
                      >
                        <div
                          class="table-title bg-primary"
                          style=""
                          v-if="footballActive == football.id"
                        >
                          <div>
                            <p style="color: white">
                              <b>
                                {{
                                  this.$i18n.locale == "cn"
                                    ? football.nameCn
                                    : football.nameEn
                                }}
                              </b>
                            </p>
                          </div>
                        </div>
                      </template>

                      <div
                        class="standing-table"
                        style="min-height: 600px !important"
                      >
                        <table
                          id="div_Table1"
                          width="100%"
                          border="1"
                          cellpadding="2"
                          cellspacing="0"
                          bgcolor="#FFFFFF"
                          class="table table-hover"
                          style="margin-bottom: 8px"
                        >
                          <thead>
                            <tr align="center" class="th-bg">
                              <td width="30">{{ $t("standings.rank") }}</td>
                              <td
                                height="18"
                                align="left"
                                style="padding-left: 8px; text-align: left"
                              >
                                {{ $t("standings.team-name") }}
                              </td>
                              <td width="32">
                                {{ $t("standings.match-played") }}
                              </td>
                              <td width="32">
                                {{ $t("standings.win") }}
                              </td>
                              <td width="32">
                                {{ $t("standings.draw") }}
                              </td>
                              <td width="32">
                                {{ $t("standings.loss") }}
                              </td>
                              <td width="32">
                                {{ $t("standings.change-total") }}
                              </td>
                              <td width="32">
                                {{ $t("standings.goals-for") }}
                              </td>
                              <td width="32">
                                {{ $t("standings.goals-against") }}
                              </td>
                              <td width="32">
                                {{ $t("standings.goals-draw") }}
                              </td>
                              <td width="50">{{ $t("standings.win") }}%</td>
                              <td width="50">{{ $t("standings.draw") }}%</td>
                              <td width="50">{{ $t("standings.loss") }}%</td>
                              <td width="28">
                                {{ $t("standings.points") }}
                              </td>
                            </tr>
                          </thead>

                          <tbody
                            v-for="league in footballStandingsData"
                            :key="league.id"
                          >
                            <tr v-if="footballStandingsData.length > 1">
                              <td colspan="14" class="bg-dark text-white">
                                <b>GROUP {{ league.group }}</b>
                              </td>
                            </tr>

                            <tr v-for="team in league.rows" :key="team.id">
                              <td class="text-center">
                                {{ team.position }}
                              </td>

                              <td>
                                <img
                                  :src="team.teamInfo.logo"
                                  alt=""
                                  class="flag-sm"
                                />

                                &nbsp;

                                {{
                                  this.$i18n.locale == "cn"
                                    ? team.teamInfo.cn_name
                                    : team.teamInfo.name
                                }}
                              </td>

                              <td class="text-center">{{ team.total }}</td>
                              <td class="text-center">{{ team.won }}</td>
                              <td class="text-center">{{ team.draw }}</td>
                              <td class="text-center">{{ team.loss }}</td>
                              <td class="text-center">
                                {{ team.deduct_points }}
                              </td>
                              <td class="text-center">{{ team.goals }}</td>
                              <td class="text-center">
                                {{ team.goals_against }}
                              </td>
                              <td class="text-center">{{ team.goal_diff }}</td>

                              <td class="text-center">
                                {{
                                  isNaN(
                                    calculatePercentage(team.won, team.total)
                                  )
                                    ? "0"
                                    : calculatePercentage(team.won, team.total)
                                }}
                              </td>
                              <td class="text-center">
                                {{
                                  isNaN(
                                    calculatePercentage(team.draw, team.total)
                                  )
                                    ? "0"
                                    : calculatePercentage(team.draw, team.total)
                                }}
                              </td>
                              <td class="text-center">
                                {{
                                  isNaN(
                                    calculatePercentage(team.loss, team.total)
                                  )
                                    ? "0"
                                    : calculatePercentage(team.loss, team.total)
                                }}
                              </td>
                              <td class="text-center">{{ team.points }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <!-- end::football section -->
            <!-- begin::Basketball section -->
            <div
              class="card"
              v-if="basketball && !loading"
              style="width: 85%; margin-left: 20px; margin-top: 20px"
            >
              <div class="tabs row no-gutters">
                <div
                  class="col-auto"
                  style="
                    background-color: rgb(52, 58, 64);
                    width: 215px !important;
                  "
                >
                  <ul
                    role="tablist"
                    class="nav nav-pills flex-column small card-header h-100 border-bottom-0 rounded-0"
                  >
                    <template
                      v-for="league in basketballLeagueList"
                      :key="league.id"
                    >
                      <li
                        role="presentation"
                        class="nav-item"
                        v-on:click="getBasketballStandings(league.id)"
                      >
                        <a
                          style="color: white"
                          role="tab"
                          aria-selected="true"
                          aria-setsize="15"
                          aria-posinset="1"
                          href="#"
                          target="_self"
                          class="nav-link"
                          :class="basketballActive == league.id ? 'active' : ''"
                        >
                          {{
                            this.$i18n.locale == "cn"
                              ? league.nameCn
                              : league.nameEnShort
                          }}
                        </a>
                      </li>
                    </template>
                  </ul>
                </div>
                <div class="tab-content col">
                  <div
                    role="tabpanel"
                    aria-hidden="false"
                    class="tab-pane active card-body"
                  >
                    <span>
                      <template
                        v-for="basketball in basketballLeagueList"
                        :key="basketball.id"
                      >
                        <div
                          class="table-title bg-primary"
                          style=""
                          v-if="basketballActive == basketball.id"
                        >
                          <div>
                            <p style="color: white">
                              <b>
                                {{
                                  this.$i18n.locale == "cn"
                                    ? basketball.nameCn
                                    : basketball.nameEn
                                }}
                              </b>
                            </p>
                          </div>
                        </div>
                      </template>

                      <div
                        class="standing-table"
                        style="min-height: 600px !important"
                      >
                        <table
                          id="div_Table1"
                          width="100%"
                          border="1"
                          cellpadding="2"
                          cellspacing="0"
                          bgcolor="#FFFFFF"
                          class="table table-hover"
                          style="margin-bottom: 8px"
                        >
                          <thead>
                            <tr align="center" class="th-bg">
                              <td width="10">{{ $t("standings.rank") }}</td>
                              <td
                                width="250"
                                align="left"
                                style="padding-left: 8px; text-align: left"
                              >
                                {{ $t("standings.team-name") }}
                              </td>

                              <td width="32">
                                {{ $t("standings.win") }}
                              </td>
                              <td width="32">
                                {{ $t("standings.loss") }}
                              </td>

                              <td width="50">{{ $t("standings.home") }}</td>
                              <td width="50">{{ $t("standings.away") }}</td>
                              <td width="50">{{ $t("standings.last-ten") }}</td>
                            </tr>
                          </thead>

                          <tbody
                            v-for="league in basketballStandingsData"
                            :key="league.id"
                          >
                            <tr v-if="basketballStandingsData.length > 1">
                              <td colspan="14" class="bg-dark text-white">
                                <b>{{ league.name }} </b>
                              </td>
                            </tr>

                            <tr v-for="team in league.rows" :key="team.id">
                              <td>
                                {{ team.position }}
                              </td>

                              <td>
                                <img
                                  :src="team.teamInfo.logo"
                                  alt=""
                                  class="flag-sm"
                                />

                                &nbsp;

                                {{
                                  this.$i18n.locale == "cn"
                                    ? team.teamInfo.cn_name
                                    : team.teamInfo.name
                                }}
                              </td>

                              <td class="text-center">{{ team.won }}</td>
                              <td class="text-center">{{ team.lost }}</td>
                              <td class="text-center">{{ team.home }}</td>
                              <td class="text-center">{{ team.away }}</td>
                              <td class="text-center">{{ team.last_10 }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <!-- end::Basketball section -->
            <!-- begin::loading section -->
            <center v-if="loading">
              <br />
              <p style="font-size: 15px !important">
                {{ $t("football.live-score.information-is-loading") }}
                <img
                  src="/images/spinner.gif"
                  alt="spinner"
                  style="width: 12px"
                />
              </p>
            </center>
            <!-- end::loading section -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <FOOTER />
</template>
<script>

export default {
  components: {

  },
  data() {
    return {
      loading: false,
      football: true,
      footballActive: "jednm9whz0ryox8",
      footballStandingsData: null,
      footballLeagueList: null,

      basketball: false,
      basketballActive: "49vjxm8xt4q6odg",
      basketballStandingsData: null,
      basketballLeagueList: null,
    };
  },
  mounted() {
    console.log("This Component is mounted Successfully...!");
  },
  created() {
    this.getFootballStandings(this.footballActive);
    this.getFootballLeagueList();
    this.getBasketballLeagueList();
    this.getBasketballStandings(this.basketballActive);
  },

  methods: {
    getFootballStandings(leagueId) {
      this.football = true;
      this.loading = true;
      this.footballActive = leagueId;
      this.$axios
        .get(
          `${this.BASE_SERVER_URI}/api6/football/standings/${this.footballActive}`
        )
        .then((response) => {
          this.footballStandingsData = response.data.standingsData;
          console.log("----------->", this.footballStandingsData);
          this.loading = false;
        });
    },
    getFootballLeagueList() {
      this.$axios
        .get(
          `${this.BASE_SERVER_URI}/api6/football/leagues-list-desktop/${this.footballActive}`
        )
        .then((response) => {
          this.footballLeagueList = response.data.leagueList;
          this.loading = false;
        });
    },

    getBasketballStandings(leagueId) {
      this.loading = true;
      this.basketballActive = leagueId;
      this.$axios
        .get(
          `${this.BASE_SERVER_URI}/api6/basketball/standings/${this.basketballActive}`
        )
        .then((response) => {
          this.basketballStandingsData = response.data.standingsData;
          this.loading = false;
        });
    },

    getBasketballLeagueList() {
      this.$axios
        .get(
          `${this.BASE_SERVER_URI}/api6/basketball/leagues-list/${this.basketballActive}`
        )
        .then((response) => {
          this.basketballLeagueList = response.data.leagueList;
          this.loading = false;
        });
    },

    calculatePercentage(currentValue, totalVale) {
      return Math.round((currentValue * 100) / totalVale);
    },
    activefoobtallData() {
      this.basketball = false;
      this.football = true;
    },
    activeBasketballData() {
      this.basketball = true;
      this.football = false;
    },
  },
};
</script>




<style scoped>
.tabs {
  flex-wrap: nowrap;
}

.card {
  overflow: auto;
}

.h-Grey {
  background-color: #e7e7e7;
  line-height: 30px !important;
  color: #333;
  text-align: left;
  font-size: 15px;
  font-weight: 600;
  padding-left: 10px;
}

.h-Orange {
  background-color: rgb(253, 252, 204) !important;
}

.h-Default {
  background-color: #0070ca !important;
  color: #ffffff;
  text-align: left;
  height: 55px;
  font-size: 16px;
  line-height: 45px;
  font-weight: 600;
  padding-left: 10px;
}

.table {
  font-size: 12px;
  overflow: hidden;
}

.title_tr {
  background-color: rgba(0, 0, 0, 0.05) !important;
  font-weight: 500;
}

#div_Table1 > tbody > tr.th-bg {
  background-color: rgba(0, 0, 0, 0.05) !important;
  font-weight: 500;
}

.table th,
.table td {
  padding: 5px;
}

.league-logo {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

.table-title {
  padding-left: 15px;
  height: 55px;
  font-size: 16px;
  line-height: 45px;
  color: white;
}

.standing-table {
  overflow: auto;
}

.icon-xs {
  width: 15px;
  height: 15px;
}

.flag-sm {
  width: 15px;
  height: 15px;
}

.east > div,
.west > div {
  height: 50px;
  color: #ffffff;
  font-size: 16px;
  line-height: 50px;
  padding-left: 20px;
  background: #49a6fc;
}

.west > div {
  background: #ec6a86;
}

@media only screen and (max-width: 991px) {
  .fix-frame {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) {
  .fix-frame {
    width: 1200px;
    margin: auto;
  }
}

.show-standing {
  display: block !important;
}

.show-top-scorer {
  display: block !important;
}
</style>
  