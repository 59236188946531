import { createRouter, createWebHistory } from "vue-router";
import WorldStanding from "./components/football/world-standing";
import LiveScoring from "./components/football/live-scoring";
import MatchSchedule from "./components/football/match-schedule";
import MatchResult from "./components/football/match-result";
import BasketBallLiveScoring from "./components/basketball/live-scoring";
import BasketBallMatchResult from "./components/basketball/match-result";
import BasketBallMatchSchedule from "./components/basketball/match-schedule";
import StandingPhone from "./components/standings/phone/standings-phone";
import StandingDesktop from "./components/standings/desktop/standings-desktop";
import AlternateUrl from "./components/pages/alternate-url";
import DownloadApp from "./components/pages/download-app";
import SportsNews from "./components/post/post-list";
import SpecificPost from "./components/post/post-details";
import POSTBYTAG from "./components/post/post-by-tag";
import VIDEOINDEX from "./components/video/video-index";
import LIVESTREAMING from "./components/live/live-anchor-list";
import LIVEANCHOR from "./components/live/live-anchor";
import VIDEO from "./components/video/video-show";
import i18n from "./i18n";
// aa

let pathName = location.pathname;
let domianName = location.href;

if (pathName.indexOf("cn/") != -1 || domianName.indexOf("cn.") != -1)
  i18n.global.locale = "cn";

if (pathName.indexOf("ty/") != -1 || domianName.indexOf("ty.") != -1)
  i18n.global.locale = "ty";

if (pathName.indexOf("vi/") != -1 || domianName.indexOf("vi.") != -1)
  i18n.global.locale = "vi";

if (pathName.indexOf("th/") != -1 || domianName.indexOf("th.") != -1)
  i18n.global.locale = "th";

const routes = [
  {
    path: "/",
    redirect: "/" + i18n.global.locale + "/live/football",
  },
  {
    path: "/" + i18n.global.locale + "/live/football",
    component: LiveScoring,
  },
  {
    path: "/" + i18n.global.locale + "/live/football/world-standing",
    component: WorldStanding,
  },
  {
    path: "/" + i18n.global.locale + "/live/football/match-schedule",
    component: MatchSchedule,
  },

  {
    path: "/" + i18n.global.locale + "/live/football/match-result",
    component: MatchResult,
  },
  {
    path: "/" + i18n.global.locale + "/live/basketball",
    component: BasketBallLiveScoring,
  },
  {
    path: "/" + i18n.global.locale + "/live/basketball/match-result",
    component: BasketBallMatchResult,
  },
  {
    path: "/" + i18n.global.locale + "/live/basketball/match-schedule",
    component: BasketBallMatchSchedule,
  },
  {
    path: "/" + i18n.global.locale + "/alternate-url",
    component: AlternateUrl,
  },
  {
    path: "/" + i18n.global.locale + "/download-app",
    component: DownloadApp,
  },
  {
    path: "/" + i18n.global.locale + "/standings/index",
    name: "standing",

    get component() {
      if (window.screen.width < 768) {
        return StandingPhone;
      } else {
        return StandingDesktop;
      }
    },
  },
  {
    path: "/" + i18n.global.locale + "/post/index",
    component: SportsNews,
  },
  {
    path: "/" + i18n.global.locale + "/post/:slug/:ty",
    component: SpecificPost,
  },
  {
    path: "/" + i18n.global.locale + "/post/tag/:tag/",
    component: POSTBYTAG,
  },
  {
    path: "/" + i18n.global.locale + "/video/index",
    component: VIDEOINDEX,
  },
  {
    path: "/" + i18n.global.locale + "/video/:ty",
    component: VIDEO,
  },
  {
    path: "/" + i18n.global.locale + "/live/anchor",
    component: LIVESTREAMING,
  },
  {
    path: "/" + i18n.global.locale + "/live/anchor/:ty",
    component: LIVEANCHOR,
  },
];
// VIDEOINDEX
export default createRouter({
  mode: "history",
  history: createWebHistory(),
  routes,
});
